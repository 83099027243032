/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useStore } from "react-hookstore";
import queryString from "query-string";
import Skeleton from "react-loading-skeleton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { DEFAULT_DAYS_FOR_HOT } from "constants/workOrderConstants";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import TrackPageComponent from "../../components/TrackPageComponents/track-page-component/TrackPageComponent";
import TrackPageComponentStyle from "../../components/TrackPageComponents/track-page-component/TrackPageComponent.module.css";
import TrackPageDataViewComponent from "../../components/TrackPageComponents/track-page-data-view-component/TrackPageDataViewComponent";
import GroupbySideBarComponent from "../../components/TrackPageComponents/groupby-sidebar-component/GroupbySidebarComponent";
import GridCardContentDefault from "../../components/TrackPageComponents/Order/grid-card-contents/grid-card-content-default/GridCardContentDefault";
import GridCardComponent from "../../components/grid-card-component/GridCardComponent";
import GridCardContentGroupbyLocation from "../../components/TrackPageComponents/Order/grid-card-contents/grid-card-content-groupby-location/GridCardContentGroupbyLocation";
import GridCardContentParts from "../../components/TrackPageComponents/Order/grid-card-contents/gird-card-content-parts/GridCardContentParts";
import OptionalLink from "../../components/optional-link/OptionalLink";
import {
  getCustomerLogo,
  getFormattedDate,
  getStackedXemelgoLogo,
  naturalSort,
  getStatusFlags,
  createConstantObject
} from "../../common/Utilities";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { fullScreenModeStore } from "../../state-managements/stores/full-screen-mode-store";
import "react-loading-skeleton/dist/skeleton.css";

import {
  OverviewDisplay,
  MetricsDisplay,
  BacklogGraphDisplay,
} from "../../components/order-overview-component/OrderOverviewComponent";
import DisplayBanner from "../../components/display-banner/DisplayBanner";
import OrderOverviewComponentStyle from "../../components/order-overview-component/OrderOverviewComponent.module.css";
import Overview from "../../components/TrackPageComponents/Overview";
import Style from "../../components/TrackPageComponents/Order/css/TrackPageGridViewComponent.module.css";
import BreadcrumbsComponent from "../../components/breadcrumbs-component/BreadcrumbsComponent";
import { ModalForm } from "../../components/modal-form";
import { configStore } from "../../state-managements/stores/config-store";
import CreateWorkOrderModal from "./CreateWorkOrderModal";
import PrintTagsModal from "../print-tags-modal";
import { ReactComponent as OrderIcon } from "../../assets/icons/order.svg";
import { userProfileStore } from "../../state-managements/stores/user-profile-store";
import xemelgoStyle from "../../styles/variable";

const APP_ID = "order";
const TRACK_PAGE_CONFIG = "trackPage";
const STATUS_MAP = "statusFlagMap";
const PART_DETAILS_LINK_ATTRIBUTE = "partName";
const mainColor = xemelgoStyle.theme.ORDER_PRIMARY;
const secondaryColor = xemelgoStyle.theme.ORDER_SECONDARY;

const TAB_IDS = createConstantObject({
  allParts: "all-parts",
  allOrders: "all-orders",
  location: "location"
});

const OrderTrackPageFeature = ({ history }) => {
  const breadcrumbsDataListRef = useRef();
  const overallOverviewDataRef = useRef();
  const locationListDataRef = useRef();
  const overallListDataRef = useRef();
  const partDataListRef = useRef();
  const autoRefreshInterval = 60000;
  const handleBreadCrumbsClick = (id) => {
    const { current } = breadcrumbsDataListRef;
    while (current.length && current[current.length - 1].id !== id) {
      current.pop();
    }
    setBreadcrumbsDataList(current);
  };

  const bulkUpdateItemsButton = {
    id: "bulk-update-items",
    display: "Bulk Update",
    linkRoute: `${history.location.pathname}/bulk-update`
  };

  const allOrdersTab = {
    id: TAB_IDS.allOrders,
    display: "All Orders",
    action: () => {
      history.replace(`${history.location.pathname}`);
      calculateOverviewData(overallOverviewDataRef.current);
      setFocusedTab(null);

      setBreadcrumbsDataList([
        {
          id: TAB_IDS.allOrders,
          value: "All Orders",
          onClick: () => {
            handleBreadCrumbsClick(TAB_IDS.allOrders);
            handleTabClick(TAB_IDS.allOrders, overallListDataRef.current);
            setFocusedTab(null);
          }
        }
      ]);
    }
  };

  const allPartsTab = {
    id: TAB_IDS.allParts,
    display: "All Parts",
    action: () => {
      history.replace(`${history.location.pathname}?group=all-parts`);
      calculateOverviewData(overallOverviewDataRef.current);
      listViewHeaderStructureListControl(TAB_IDS.allParts);
      setFocusedTab(null);

      setBreadcrumbsDataList([
        {
          id: TAB_IDS.allParts,
          value: "All Parts",
          onClick: () => {
            handleBreadCrumbsClick(TAB_IDS.allParts);
            handleTabClick(TAB_IDS.allParts, partDataListRef.current);
            setFocusedTab(null);
          }
        }
      ]);
    }
  };

  const groupByLocationTab = {
    id: TAB_IDS.location,
    display: "Location",
    action: () => {
      handleTabClick(TAB_IDS.location, locationListDataRef.current.locations);
      setFocusedTab(null);
      calculateOverviewData(overallOverviewDataRef.current);
      history.replace(`${history.location.pathname}?group=location`);
      setBreadcrumbsDataList([]);
    }
  };

  const groupByHeaderAndTabs = {
    id: "groupby",
    display: "Group By",
    tabStructure: []
  };

  const tabsMap = {
    "all-parts": allPartsTab,
    "all-orders": allOrdersTab
  };

  const sidebarFeatureButtonMap = {
    "bulk-update-items": bulkUpdateItemsButton
  };

  const groupByTabsMap = {
    location: groupByLocationTab
  };

  const configProvider = useAppConfigProvider(APP_ID);
  const SolutionStatuses = configProvider.getValue(STATUS_MAP, "object");
  const TrackPageConfig = configProvider.getValue(TRACK_PAGE_CONFIG, "object");
  const [currentTab, setCurrentTab] = useState(null);
  const [headerStructure, setHeaderStructure] = useState([]);
  const [loading, setLoading] = useState(true);
  const [overallOverviewData, setOverallOverviewData] = useState({});
  const [overallListData, setOverallListData] = useState({});
  const [ordersAtLocationData, setOrdersAtLocationData] = useState({});
  const [locationListData, setLocationListData] = useState({});
  const [currentDataList, setCurrentDataList] = useState([]);
  const [locationsDataList, setLocationsDataList] = useState([]);
  const [batchUpdateOptions, setBatchUpdateOptions] = useState([]);
  const [selectedBatchOption, setSelectedBatchUpdateOption] = useState(undefined);
  const [showForm, setShowForm] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(undefined);
  const [logoData, setLogoData] = useState(null);
  const [client] = useState(useXemelgoClient());
  const [customerConfiguration] = useStore(configStore);
  const [overviewData, setOverviewData] = useState({});
  const [partDataList, setPartDataList] = useState([]);
  const [focusedTab, setFocusedTab] = useState(null); // This is used only when currentTab is null but you want the side bar to focused on specific id
  const [breadcrumbsDataList, setBreadcrumbsDataList] = useState([]);
  const [fullScreenMode, setFullScreenMode] = useStore(fullScreenModeStore);
  const [defaultViewMode, setDefaultViewMode] = useState(null);
  const [defaultSortMethod, setDefaultSortMethod] = useState(null);
  const [gridViewConfig, setGridViewConfig] = useState({});
  const [listViewConfig, setListViewConfig] = useState({});
  const [groupbyStructure, setGroupByStructure] = useState(null);
  const [disablePagination, setDisablePagination] = useState(false);
  const [enableAutoRefresh, setEnableAutoRefresh] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(autoRefreshInterval);
  const [overviewLoaded, setOverviewLoaded] = useState(false);
  const [listLoaded, setListLoaded] = useState(false);
  const [showAddOrderModal, setShowAddOrderModal] = useState(false);
  const [isCreateWorkOrderV2Enabled, setIsCreateWorkOrderV2Enabled] = useState(false);
  const [showDisplayBanner, setShowDisplayBanner] = useState(false);
  const [displayBannerError, setDisplayBannerError] = useState(false);
  const [displayBannerMessage, setDisplayBannerMessage] = useState("");
  const [userProfile] = useStore(userProfileStore);

  const handleTabClick = async (tabId, dataList) => {
    setCurrentTab(tabId);
    setCurrentDataList(dataList);
    listViewHeaderStructureListControl(tabId);
  };

  useEffect(() => {
    if (Object.keys(locationListData).length || Object.keys(overallListData).length) {
      const queryPayload = queryString.parse(history.location.search);
      if (queryPayload.group === TAB_IDS.location) {
        processQueryPayload(queryPayload);
      } else if (queryPayload.group === TAB_IDS.allParts) {
        if (!partDataList.length) {
          loadAllOrders();
        }
        setCurrentDataList(partDataList);
        setCurrentTab(TAB_IDS.allParts);
        listViewHeaderStructureListControl(TAB_IDS.allParts);
      } else {
        if (!overallListData.tracksItem) {
          loadAllOrders();
        }
        if (overallListData.tracksItem) {
          setCurrentDataList(
            overallListData.tracksItem.map((element) => {
              return { ...element, isChecked: false };
            })
          );
          setCurrentTab(TAB_IDS.allOrders);
          listViewHeaderStructureListControl(TAB_IDS.allOrders);
        }
      }
    }
    // eslint-disable-next-line
  }, [history.location, locationListData, ordersAtLocationData]);

  useEffect(() => {
    breadcrumbsDataListRef.current = breadcrumbsDataList;
    partDataListRef.current = partDataList;
    overallOverviewDataRef.current = overallOverviewData;
    overallListDataRef.current = overallListData;
    locationListDataRef.current = locationListData;
  }, [breadcrumbsDataList, partDataList, overallOverviewData, locationListData, overallListData]);

  const calculateOverviewData = (data) => {
    const tempOverview = {
      numOfDelayed: data.delayedTasks,
      numOfOrders: data.totalTasks,
      numOfOnTime: data.onTimeTasks,
      averageVelocity: 0,
      health: "Healthy",
      numOfExpedited: data.expeditedTasks,
      velocityLabel: undefined,
      graphDataList: []
    };

    if (data.locations) {
      tempOverview.velocityLabel = "Avg. Velocity of Orders (orders/day)";
      tempOverview.graphDataList = data.locations.map((each) => {
        let locationHealth = "Healthy";
        if (each.totalTasks >= each.criticalThreshold) {
          locationHealth = "Critical";
        } else if (each.totalTasks >= each.warningThreshold) {
          locationHealth = "Warning";
        }
        return {
          departmentName: each.name,
          simpleName: each.name.substring(0, 4),
          departmentID: each.id,
          numberOfExpeditedOrders: each.expeditedTasks,
          numberOfNonExpeditedOrders: each.totalTasks - each.expeditedTasks,
          totalOrders: each.totalTasks,
          currentThresholdState: locationHealth
        };
      });
      tempOverview.graphDataList = naturalSort(tempOverview.graphDataList, "simpleName");
    }

    if (data.averageVelocity) {
      tempOverview.averageVelocity = data.averageVelocity;
    } else if (data.velocity) {
      tempOverview.averageVelocity = data.velocity;
    }

    if (tempOverview.numOfOrders >= data.criticalThreshold) {
      tempOverview.health = "Critical";
    } else if (tempOverview.numOfOrders >= data.warningThreshold) {
      tempOverview.health = "Warning";
    }
    setOverviewData(tempOverview);
  };

  const getSidebarFeatureButtons = () => {
    const { sidebarFeatureButtons } = TrackPageConfig;

    return (
      sidebarFeatureButtons &&
      sidebarFeatureButtons.map((featureButton) => {
        const featureButtonDetail = sidebarFeatureButtonMap[featureButton.id];

        return (
          featureButtonDetail && (
            <OptionalLink
              key={featureButton.id}
              active={!!featureButtonDetail.linkRoute}
              route={featureButtonDetail.linkRoute}
            >
              <div
                name={featureButton.display}
                className={TrackPageComponentStyle.create_button}
                onClick={featureButtonDetail.onClick}
              >
                {featureButton.display}
              </div>
            </OptionalLink>
          )
        );
      })
    );
  };

  const processQueryPayload = (payload) => {
    let tabId = null;
    let dataList = [];
    const newBreadcrumbsDataList = [];
    switch (payload.group) {
      case TAB_IDS.location:
        tabId = TAB_IDS.location;
        dataList = locationListData.locations;
        newBreadcrumbsDataList.push({
          id: TAB_IDS.location,
          value: "Location",
          onClick: () => {
            history.replace(`${history.location.pathname}?group=location`);
            handleBreadCrumbsClick(TAB_IDS.location);
            handleTabClick(TAB_IDS.location, locationListData.locations);
            calculateOverviewData(overallOverviewData);
            setFocusedTab(null);
          }
        });

        break;
      case TAB_IDS.allParts:
        tabId = TAB_IDS.allParts;
        dataList = partDataList;
        newBreadcrumbsDataList.push({
          id: TAB_IDS.allParts,
          value: "All Parts",
          onClick: () => {
            handleBreadCrumbsClick(TAB_IDS.allParts);
            handleTabClick(TAB_IDS.allParts, partDataList);
            setFocusedTab(null);
          }
        });
        break;
      default:
        tabId = null;
        dataList = overallListData.tracksItem;
        break;
    }
    if (payload.id) {
      setFocusedTab(tabId);
      if (ordersAtLocationData.tracksItem == null) {
        loadOrdersAtLocation(payload.id);
      }
      const locationData = ordersAtLocationData.tracksItem || [];
      const overviewDataLocation = overallOverviewData.locations.find((each) => {
        return each.id === payload.id;
      });
      const locationName = overviewDataLocation.name;

      calculateOverviewData(overviewDataLocation);
      tabId = null;
      dataList = locationData;
      newBreadcrumbsDataList.push({
        id: payload.id,
        value: locationName
      });
    }
    setBreadcrumbsDataList(newBreadcrumbsDataList);
    handleTabClick(tabId, dataList);
  };

  const getSortSchema = () => {
    switch (currentTab) {
      case TAB_IDS.allParts:
        return [
          {
            id: "partNumber",
            value: "Part Number",

            compareFunc:
              customerConfiguration.config.components.item.ItemRelationshipConstraints["Work Order Traveller"].contains
                .part.quantity === "many"
                ? (a, b) => {
                    return a.name.localeCompare(b.name, undefined, {
                      numeric: true,
                      sensitivity: "base"
                    });
                  }
                : (a, b) => {
                    return a.id.localeCompare(b.id, undefined, {
                      numeric: true,
                      sensitivity: "base"
                    });
                  },
            type: "character"
          },
          {
            id: "orderNumber",
            value: "Order Number",
            compareFunc: (a, b) => {
              return a.orderNumber.localeCompare(b.orderNumber, undefined, {
                numeric: true,
                sensitivity: "base"
              });
            },
            type: "character"
          },
          {
            id: "lastseenlocation",
            value: "Last Seen Location",
            compareFunc: (a, b, reverse) => {
              if (a.lastKnownLocation === b.lastKnownLocation) {
                return 0;
              }
              if (!a.lastKnownLocation) {
                return reverse ? -1 : 1;
              }
              if (!b.lastKnownLocation) {
                return reverse ? 1 : -1;
              }
              return a.lastKnownLocation < b.lastKnownLocation ? -1 : 1;
            },
            type: "character"
          },
          {
            id: "status",
            value: "Status",
            default: true,
            compareFunc: (a, b) => {
              const statusLevelCalculator = (id) => {
                switch (id) {
                  case "Delayed":
                  case "time-sensitive":
                    return 8;
                  case "Expedited":
                  case "overtime":
                    return 4;
                  case "hot":
                  case "rework":
                    return 2;
                  case "On-Time":
                    return 1;
                  default:
                    return 0;
                }
              };
              let aLevel = 0;
              let bLevel = 0;
              a.represents.status_flag.forEach((each) => {
                aLevel += statusLevelCalculator(each);
              });
              b.represents.status_flag.forEach((each) => {
                bLevel += statusLevelCalculator(each);
              });
              return aLevel < bLevel ? 1 : -1;
            }
          }
        ];
      case TAB_IDS.location:
        return [
          {
            id: "locationname",
            value: "Location Name",
            compareFunc: (a, b) => {
              return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: "base"
              });
            },
            type: "character",
            default: true
          },
          {
            id: "numoforders",
            value: "# of Orders",
            compareFunc: (a, b) => {
              return a.tracksItem.length > b.tracksItem.length ? 1 : -1;
            },
            type: "number"
          },
          {
            id: "lastupdated",
            value: "Last Updated",
            compareFunc: (a, b, reverse) => {
              if (a.lastUpdatedTime === b.lastUpdatedTime) {
                return 0;
              }
              if (!a.lastUpdatedTime) {
                return reverse ? -1 : 1;
              }
              if (!b.lastUpdatedTime) {
                return reverse ? 1 : -1;
              }
              return a.lastUpdatedTime < b.lastUpdatedTime ? -1 : 1;
            },
            type: "number"
          }
        ];
      default:
        return [
          {
            id: "ordernumber",
            value: "Order Number",
            default: defaultSortMethod === "ordernumber",
            compareFunc: (a, b) => {
              return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: "base"
              });
            },
            type: "character"
          },
          {
            id: "duedate",
            value: "Due Date",
            default: defaultSortMethod === "duedate",
            compareFunc: (a, b, reverse) => {
              if (a.represents.due_date === b.represents.due_date) {
                return 0;
              }
              if (!a.represents.due_date) {
                return reverse ? -1 : 1;
              }
              if (!b.represents.due_date) {
                return reverse ? 1 : -1;
              }
              return a.represents.due_date < b.represents.due_date ? -1 : 1;
            },
            type: "number"
          },
          {
            id: "creationdate",
            value: "Creation Date",
            default: defaultSortMethod === "creationdate",
            compareFunc: (a, b) => {
              return +(a.startTime > b.startTime) || -(a.startTime < b.startTime);
            },
            type: "number"
          },
          {
            id: "lastupdated",
            value: "Last Updated Date",
            default: defaultSortMethod === "lastupdated",
            compareFunc: (a, b) => {
              return +(a.lastUpdatedTime > b.lastUpdatedTime) || -(a.lastUpdatedTime < b.lastUpdatedTime);
            },
            type: "number"
          },
          {
            id: "lastseenlocation",
            value: "Last Seen Location",
            default: defaultSortMethod === "lastseenlocation",
            compareFunc: (a, b, reverse) => {
              if (a.lastKnownLocation.name === b.lastKnownLocation.namme) {
                return 0;
              }
              if (!a.lastKnownLocation.name) {
                return reverse ? -1 : 1;
              }
              if (!b.lastKnownLocation.name) {
                return reverse ? 1 : -1;
              }
              return a.lastKnownLocation.name < b.lastKnownLocation.name ? -1 : 1;
            },
            type: "character"
          },
          {
            id: "status",
            value: "Status",
            default: defaultSortMethod === "status",
            compareFunc: (a, b) => {
              const statusLevelCalculator = (id) => {
                switch (id) {
                  case "Delayed":
                  case "time-sensitive":
                    return 8;
                  case "Expedited":
                  case "overtime":
                    return 4;
                  case "hot":
                  case "rework":
                    return 2;
                  case "On-Time":
                    return 1;
                  default:
                    return 0;
                }
              };
              let aLevel = 0;
              let bLevel = 0;
              a.represents.status_flag.forEach((each) => {
                aLevel += statusLevelCalculator(each);
              });
              b.represents.status_flag.forEach((each) => {
                bLevel += statusLevelCalculator(each);
              });
              return aLevel < bLevel ? 1 : -1;
            }
          }
        ];
    }
  };

  const onLoad = async () => {
    const { config = {} } = customerConfiguration;
    const { solutionConfiguration = {}, webClient = {} } = config;
    const { appConfigurationMap = {} } = webClient;
    const { trackPageConfiguration } = solutionConfiguration;
    const { order = {} } = appConfigurationMap;
    const { trackPage = {}, addOrder = {} } = order;
    const { v2 } = addOrder;
    const {
      defaultTab,
      defaultViewMode: newDefaultViewMode,
      listViewConfig: newListViewConfig,
      gridViewConfig: newGridViewConfig,
      possibleGroupbyTabs,
      possibleTabs,
      disablePagination: newDisablePagination = false,
      enableAutoRefresh: newEnableAutoRefresh,
      refreshInterval: newRefreshInterval
    } = trackPage;

    setIsCreateWorkOrderV2Enabled(!!v2);

    const { groupbyEnabled, defaultSort } = trackPageConfiguration;
    const tempGroupByStructure = [];
    const tempGroupByTabStructure = [];

    for (let i = 0; i < possibleTabs.length; i++) {
      tempGroupByStructure.push(tabsMap[possibleTabs[i]]);
    }

    if (groupbyEnabled && possibleGroupbyTabs.length) {
      for (let j = 0; j < possibleGroupbyTabs.length; j++) {
        tempGroupByTabStructure.push(groupByTabsMap[possibleGroupbyTabs[j]]);
      }
      groupByHeaderAndTabs.tabStructure = tempGroupByTabStructure;
      tempGroupByStructure.push(groupByHeaderAndTabs);
    }

    if (!fullScreenMode && !enableAutoRefresh) {
      switch (defaultTab) {
        case "location":
          history.replace(`${history.location.pathname}?group=location`);
          break;
        case "all-parts":
          history.replace(`${history.location.pathname}?group=all-parts`);
          break;
        default:
          history.replace(`${history.location.pathname}`);
          break;
      }
    }

    if (!groupbyStructure) {
      setGroupByStructure(tempGroupByStructure);
    }
    if (!currentTab) {
      setCurrentTab(defaultTab);
    }
    if (!focusedTab) {
      setFocusedTab(defaultTab);
    }
    if (!defaultSortMethod) {
      setDefaultSortMethod(defaultSort);
    }
    if (!defaultViewMode) {
      setDefaultViewMode(newDefaultViewMode);
    }
    if (newRefreshInterval) {
      setRefreshInterval(newRefreshInterval);
    }

    if (history.location.search === "") {
      setCurrentTab(TAB_IDS.allOrders);
      listViewHeaderStructureListControl(TAB_IDS.allOrders, newListViewConfig);
      setFocusedTab(TAB_IDS.allOrders);
    } else if (history.location.search === "?group=all-parts") {
      setCurrentTab(TAB_IDS.allParts);
      listViewHeaderStructureListControl(TAB_IDS.allParts, newListViewConfig);
      setFocusedTab(TAB_IDS.allParts);
    } else if (history.location.search === "?group=location") {
      setCurrentTab(TAB_IDS.location);
      listViewHeaderStructureListControl(TAB_IDS.location, newListViewConfig);
      setFocusedTab(TAB_IDS.location);
    }

    setListViewConfig(newListViewConfig);
    setGridViewConfig(newGridViewConfig);
    setDisablePagination(newDisablePagination);
    setEnableAutoRefresh(newEnableAutoRefresh);
    setLoading(false);

    loadOverviewContent();
  };

  const loadOrdersAtLocation = async (locationId) => {
    const { config = {} } = customerConfiguration;
    const { trackPage = {}, daysForHot = DEFAULT_DAYS_FOR_HOT } = config?.webClient?.appConfigurationMap?.order || {};
    const { additionalAttributeMap = { items: [], itemTypes: [] }, batchUpdateOptions: configOptions = [] } = trackPage;

    setListLoaded(false);
    setBatchUpdateSetting(configOptions);

    const trackPageClient = client.getTrackPageClient();
    const data = await trackPageClient.getWorkOrderAtLocation(locationId, daysForHot, additionalAttributeMap);
    setOrdersAtLocationData(data);
    setListLoaded(true);
    return data;
  };

  const loadAllOrders = async () => {
    setListLoaded(false);
    const { config = {} } = customerConfiguration;
    const { trackPage = {}, daysForHot = DEFAULT_DAYS_FOR_HOT } = config?.webClient?.appConfigurationMap?.order || {};
    const { additionalAttributeMap = { items: [], itemTypes: [] }, batchUpdateOptions: configOptions = [] } = trackPage;

    const trackingConfig = customerConfiguration.config.components.tracking;
    const locationCategories =
      trackingConfig.possibleDetectorLocations && trackingConfig.possibleDetectorLocations.length > 0
        ? trackingConfig.possibleDetectorLocations
        : ["Department"];

    setBatchUpdateSetting(configOptions);

    const TrackPageClient = client.getTrackPageClient();
    const data = await TrackPageClient.getAllWorkOrders(
      [TAB_IDS.location],
      locationCategories,
      additionalAttributeMap,
      daysForHot
    );
    const partData = [];
    data.tracksItem.forEach((item) => {
      const { parts } = item.represents;
      parts.forEach((part) => {
        const partObj = part;
        partObj.id = part.id;
        partObj.partTypeIdentifier = part.partTypeIdentifier;
        partObj.orderId = item.id;
        partObj.orderNumber = item.name;
        partObj.lastKnownLocation = part.partLocation;
        partObj.represents = {
          status_flag: item.represents.status_flag
        };
        partObj.lastUpdatedTime = item.lastUpdatedTime;
        partObj.vid = part.vid;
        partObj.startTime = item.startTime;
        partObj.due_date = item.represents.due_date;
        partData.push(partObj);
      });
    });
    setPartDataList(partData);
    setOverallListData(data);
    setListLoaded(true);
    return data;
  };

  const setBatchUpdateSetting = async (configOptions) => {
    const tempBatchUpdateOptions = [];
    for (const option of configOptions) {
      const { id, label, adminOnly } = option;
      if (adminOnly && !userProfile.isSuperAdmin) {
        continue;
      }
      switch (id) {
        case "setLocation":
          const { locationCategory } = option;
          const LocationClient = await client.getLocationClient();
          const batchUpdateLocations = await LocationClient.getLocationsOfCategory(locationCategory);
          setLocationsDataList(
            batchUpdateLocations.map((location) => {
              return {
                id: location.id,
                name: location.name || location.identifier
              };
            })
          );
          tempBatchUpdateOptions.push({
            label,
            onClick: () => {
              setSelectedBatchUpdateOption("setLocation");
            }
          });
          break;
        case "deleteOrders":
          tempBatchUpdateOptions.push({
            label: <span className={TrackPageComponentStyle.red_text}>{label}</span>,
            onClick: () => {
              setSelectedBatchUpdateOption("deleteOrders");
              setShowForm(true);
            }
          });
          break;
        case "markAsComplete":
          tempBatchUpdateOptions.push({
            label,
            onClick: () => {
              setSelectedBatchUpdateOption("markAsComplete");
              setShowForm(true);
            }
          });
          break;
        case "printTags":
          tempBatchUpdateOptions.push({
            label,
            onClick: () => {
              setSelectedBatchUpdateOption("printTags");
            }
          });
          break;
        default:
          break;
      }
    }
    setBatchUpdateOptions(tempBatchUpdateOptions);
  };

  const loadOverviewContent = async () => {
    const { config = {} } = customerConfiguration;
    const { webClient = {} } = config;
    const { appConfigurationMap = {} } = webClient;
    const { order = {} } = appConfigurationMap;
    const { daysForHot = DEFAULT_DAYS_FOR_HOT } = order;

    const trackingConfig = customerConfiguration.config.components.tracking;
    const locationCategories =
      trackingConfig.possibleDetectorLocations && trackingConfig.possibleDetectorLocations.length > 0
        ? trackingConfig.possibleDetectorLocations
        : ["Department"];

    getCustomerLogo().then((logo) => {
      if (!logo) {
        logo = getStackedXemelgoLogo("dark");
      }
      setLogoData(logo);
    });

    const queryPayload = queryString.parse(history.location.search);
    const WorkOrderClient = client.getWorkOrderClient();
    const data = await WorkOrderClient.getWorkOrderOverviewData([TAB_IDS.location], locationCategories, daysForHot);
    calculateOverviewData(data);
    setOverallOverviewData(data);
    setLocationListData(data);
    setOverviewLoaded(true);
    if (queryPayload.group === TAB_IDS.location && !queryPayload.id) {
      setListLoaded(true);
    }
    return data;
  };

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Wait until both the list/overview are retrieved before parsing the query string
    if (
      Object.keys(overallOverviewData).length &&
      Object.keys(locationListData).length &&
      Object.keys(listViewConfig).length
    ) {
      const queryPayload = queryString.parse(history.location.search);
      if (queryPayload.group === TAB_IDS.location) {
        listViewHeaderStructureListControl(
          queryPayload.group && queryPayload.id ? TAB_IDS.allOrders : queryPayload.group,
          listViewConfig
        );
      } else if (queryPayload.group === TAB_IDS.allParts) {
        setCurrentDataList(partDataList);
        setCurrentTab(TAB_IDS.allParts);
        listViewHeaderStructureListControl(TAB_IDS.allParts, listViewConfig);
      } else {
        setCurrentDataList(overallListData.tracksItem);
        setCurrentTab(TAB_IDS.allOrders);
        listViewHeaderStructureListControl(TAB_IDS.allOrders, listViewConfig);
      }
    }
  }, [overallOverviewData, overallListData, listViewConfig]);

  const processStatusFlag = (status_flag) => {
    const statusFlags = getStatusFlags(status_flag, SolutionStatuses);
    statusFlags.sort((a, b) => {
      return a.id > b.id;
    });
    return statusFlags;
  };

  const renderGridCardComponentDefault = (eachItemData, id, containerStyle) => {
    const currentGridViewConfig = gridViewConfig[currentTab || TAB_IDS.allOrders] || {};
    const { bottomContentList = [], title = {}, subTitle = {} } = currentGridViewConfig;
    const { status_flag } = eachItemData.represents;
    const statusFlags = processStatusFlag(status_flag);
    return (
      <GridCardComponent
        key={id}
        containerStyle={containerStyle}
        color={statusFlags.length ? statusFlags[0].color : "lightgrey"}
      >
        <div className={Style.card_container}>
          <OptionalLink route={`${history.location.pathname}/detail?itemId=${eachItemData.id}`}>
            <GridCardContentDefault
              orderNumber={eachItemData.name}
              image={logoData}
              rfid={eachItemData.sensor_profile_vid ? eachItemData.sensor_profile_vid : "-Not Tracked By Sensors-"}
              statusFlags={statusFlags}
              bottomContentList={bottomContentList?.map((each) => {
                return { ...each, value: eachItemData[each.id] };
              })}
              title={{ ...title, value: eachItemData[title.id] }}
              subTitle={{ ...subTitle, value: eachItemData[subTitle.id] }}
            />
          </OptionalLink>
        </div>
      </GridCardComponent>
    );
  };

  const renderGridCardComponentParts = (eachItemData, id, containerStyle) => {
    const { status_flag } = eachItemData.represents;
    const statusFlags = processStatusFlag([...status_flag, ...eachItemData.status_flags]);
    return (
      <GridCardComponent
        key={`${id}${eachItemData.orderId}`}
        containerStyle={containerStyle}
        color={statusFlags.length ? statusFlags[0].color : "lightgrey"}
      >
        <div className={Style.card_container}>
          <OptionalLink route={`${history.location.pathname}/part/detail?itemId=${eachItemData.id}`}>
            <GridCardContentParts
              partNumber={
                eachItemData.name ? `${eachItemData.identifier} - ${eachItemData.name}` : eachItemData.identifier
              }
              orderNumber={eachItemData.orderNumber}
              image={logoData}
              statusFlags={statusFlags}
              location={eachItemData.lastKnownLocation}
              quantity={eachItemData.quantity}
            />
          </OptionalLink>
        </div>
      </GridCardComponent>
    );
  };

  const renderGridCardComponentGroupbyLocation = (eachData, id, containerStyle) => {
    const departmentOverview = overviewData.graphDataList.filter((each) => {
      return each.departmentID === eachData.id;
    });

    let color = "";
    switch (departmentOverview[0].currentThresholdState) {
      case "Healthy":
        color = "#00B200";
        break;
      case "Warning":
        color = "#FFCF0F";
        break;
      case "Critical":
        color = "#F62227";
        break;
      default:
        color = "lightgrey";
        break;
    }
    return (
      <GridCardComponent
        onClick={async () => {
          const locationOverviewData = overallOverviewData.locations.find((each) => {
            return each.id === id;
          });
          await loadOrdersAtLocation(id);
          calculateOverviewData(locationOverviewData);

          breadcrumbsDataList.push({
            id,
            value: locationOverviewData.name
          });
          setBreadcrumbsDataList(breadcrumbsDataList);
        }}
        key={id}
        color={color}
        containerStyle={containerStyle}
      >
        <div style={{ width: "100%" }}>
          <OptionalLink
            route={`${history.location.pathname}${history.location.search}&id=${id}`}
            isReplace
          >
            <GridCardContentGroupbyLocation
              locationName={eachData.name}
              numOfOrders={eachData.totalTasks}
              lastUpdatedTime={eachData.lastUpdatedTime}
              velocity={eachData.velocity}
            />
          </OptionalLink>
        </div>
      </GridCardComponent>
    );
  };

  const renderGridCard = () => {
    switch (currentTab) {
      case TAB_IDS.allParts:
        return renderGridCardComponentParts;
      case TAB_IDS.location:
        return renderGridCardComponentGroupbyLocation;
      default:
        return renderGridCardComponentDefault;
    }
  };

  const filterFunction = (input, each) => {
    let match = false;
    const lowerCaseInput = input.toLowerCase();
    const { name, vid, sensorProfile, lastKnownLocation, represents, orderNumber, identifier } = each;
    const { status_flag } = represents || {};
    switch (currentTab) {
      case TAB_IDS.allParts:
        if (
          (name && name.toLowerCase().includes(lowerCaseInput)) ||
          (identifier && identifier.toLowerCase().includes(lowerCaseInput)) ||
          (orderNumber && orderNumber.toLowerCase().includes(lowerCaseInput)) ||
          (vid && vid.toLowerCase().includes(lowerCaseInput)) ||
          (lastKnownLocation && lastKnownLocation.toLowerCase().includes(lowerCaseInput))
        ) {
          match = true;
        }
        status_flag.forEach((eachflag) => {
          if (eachflag.toLowerCase().includes(lowerCaseInput)) {
            match = true;
          }
        });

        break;
      case TAB_IDS.location:
        if (name && name.toLowerCase().includes(lowerCaseInput)) {
          match = true;
        }
        break;
      default:
        if (
          name.toLowerCase().includes(lowerCaseInput) ||
          (sensorProfile && sensorProfile.vid.toLowerCase().includes(lowerCaseInput)) ||
          (lastKnownLocation.name && lastKnownLocation.name.toLowerCase().includes(lowerCaseInput))
        ) {
          match = true;
        }
        status_flag.forEach((eachflag) => {
          if (eachflag.toLowerCase().includes(lowerCaseInput)) {
            match = true;
          }
        });
        break;
    }
    return match;
  };
  const listViewFilterFunction = (input, each) => {
    let match = true;
    const lowerCaseInput = input && input.toLowerCase();
    if (input) {
      const keywords = lowerCaseInput.trim().split(" ");
      for (const keyword of keywords) {
        if (!each.searchString.toLowerCase().includes(keyword)) {
          match = false;
          break;
        }
      }
    }
    return match;
  };

  const listViewHeaderStructureListControl = (groupby, parameterListViewConfig) => {
    const config = parameterListViewConfig || listViewConfig;
    const currentConfig = config[groupby || TAB_IDS.allOrders] || {};
    const tempHeaderStructure = Object.keys(currentConfig)
      .sort((a, b) => {
        return currentConfig[a].index - currentConfig[b].index;
      })
      .map((eachId) => {
        const { type, format = "hh:mm A MMM D", defaultValue = "-" } = currentConfig[eachId];
        let renderComponent;
        switch (type) {
          case "statusText":
            renderComponent = (statusList) => {
              return (
                statusList &&
                Array.isArray(statusList) &&
                statusList.length > 0 &&
                statusList.map((eachStatus) => {
                  return (
                    <div
                      key={eachStatus.id}
                      className={Style.list_view_status}
                      style={{ backgroundColor: eachStatus && eachStatus.color }}
                    >
                      {eachStatus ? eachStatus.displayText : "-"}
                    </div>
                  );
                })
              );
            };
            break;
          case "date":
            renderComponent = (data) => {
              return data ? getFormattedDate(data, format) : "-";
            };
            break;
          default:
            renderComponent = (data) => {
              return <p>{data || typeof data === "number" ? data : defaultValue}</p>;
            };
            break;
        }
        return { id: eachId, ...currentConfig[eachId], renderComponent };
      });
    setHeaderStructure(tempHeaderStructure);
  };

  const onMultiClick = async (ids, value) => {
    const tempCurrentDataList = [...currentDataList];
    ids.forEach((id) => {
      const currentObject = tempCurrentDataList.find((element) => {
        return element.id === id;
      });
      currentObject.isChecked = value;
    });

    setCurrentDataList(tempCurrentDataList);
  };

  const onClickAway = () => {
    if (!showForm) {
      setSelectedBatchUpdateOption(undefined);
    }
  };

  const batchUpdateFinalAction = async () => {
    setListLoaded(false);
    setOverviewLoaded(false);
    const queryPayload = queryString.parse(history.location.search);
    if (Object.entries(queryPayload).length) {
      loadOverviewContent();
      await loadOrdersAtLocation(queryPayload.id);
    } else {
      await loadAllOrders();
    }
  };

  const batchUpdateSubmit = async () => {
    setLoading(true);
    const selectedOrders = [];
    currentDataList.forEach((element) => {
      if (element.isChecked) {
        selectedOrders.push(element);
      }
    });

    const orderIds = selectedOrders.map((order) => {
      return order.id;
    });
    const WorkOrderClient = client.getWorkOrderClient();

    switch (selectedBatchOption) {
      case "setLocation":
        const PublishClient = client.getPublishClient();
        try {
          await PublishClient.publishUserEvent(orderIds, selectedLocation);
        } catch (e) {
          console.log(e);
        } finally {
          batchUpdateFinalAction();
        }
        break;
      case "deleteOrders":
        try {
          await WorkOrderClient.deleteWorkOrders(orderIds);
        } catch (e) {
          console.log(e);
        } finally {
          batchUpdateFinalAction();
        }
        break;
      case "markAsComplete":
        try {
          const promises = orderIds.map((orderId) => {
            return WorkOrderClient.markWorkOrderAsComplete(orderId);
          });
          await Promise.all(promises);
        } catch (e) {
          console.log(e);
        } finally {
          batchUpdateFinalAction();
        }
        break;
      default:
        break;
    }
    setSelectedBatchUpdateOption(undefined);
  };

  const getFormComponent = () => {
    switch (selectedBatchOption) {
      case "setLocation":
        const sortedLocations = naturalSort(locationsDataList, "name");
        return (
          <ClickAwayListener onClickAway={onClickAway}>
            <div className={TrackPageComponentStyle.set_location_container}>
              Set Location
              <select
                className={TrackPageComponentStyle.set_location_dropdown}
                onChange={(event) => {
                  setSelectedLocation(event.target.value);
                }}
                value={selectedLocation || sortedLocations[0]?.id}
              >
                {sortedLocations.map((location) => {
                  return (
                    <option
                      key={location.id}
                      value={location.id}
                    >
                      {location.name}
                    </option>
                  );
                })}
              </select>
              <div className={TrackPageComponentStyle.set_location_buttons}>
                <div
                  className={TrackPageComponentStyle.cancel_button}
                  onClick={() => {
                    setSelectedBatchUpdateOption(undefined);
                  }}
                >
                  Cancel
                </div>
                <div
                  data-cy-order-page-set-location-submit-button
                  className="submit-button-small"
                  onClick={() => {
                    if (!selectedLocation) {
                      setSelectedLocation(sortedLocations[0]?.id);
                    }
                    setShowForm(true);
                  }}
                >
                  Submit
                </div>
              </div>
            </div>
          </ClickAwayListener>
        );
      default:
        return undefined;
    }
  };

  const getCheckedCount = () => {
    let count = 0;
    currentDataList.forEach((element) => {
      if (element.isChecked) {
        count++;
      }
    });
    return count;
  };

  const renderModalTitle = () => {
    switch (selectedBatchOption) {
      case "setLocation":
        return <div>{`Move ${getCheckedCount()} Item(s)?`}</div>;
      case "deleteOrders":
        return <div>{`Delete ${getCheckedCount()} Item(s)?`}</div>;
      case "markAsComplete":
        return <div>{`Mark ${getCheckedCount()} Item(s) as Complete?`}</div>;
      default:
        return <></>;
    }
  };

  const renderModalBody = () => {
    if (loading) {
      return <div>Processing....</div>;
    }
    switch (selectedBatchOption) {
      case "setLocation":
        const location = locationsDataList.find((location) => {
          return location.id === selectedLocation;
        });
        return <div>{`The selected ${getCheckedCount()} item(s) will be moved to ${location?.name}.`}</div>;
      case "deleteOrders":
        return <div>{`The selected ${getCheckedCount()} item(s) will be deleted.`}</div>;
      case "markAsComplete":
        return <div>{`The selected ${getCheckedCount()} item(s) will be marked as complete`}</div>;
      default:
        return <></>;
    }
  };

  const renderModalFooter = () => {
    switch (selectedBatchOption) {
      case "deleteOrders":
      case "setLocation":
      case "markAsComplete":
        return (
          <div className={TrackPageComponentStyle.modal_footer_container}>
            <div
              className={TrackPageComponentStyle.cancel_button}
              onClick={() => {
                setShowForm(false);
              }}
            >
              Cancel
            </div>
            <div
              data-cy-work-page-batch-option-confirm
              onClick={async () => {
                if (!loading) {
                  await batchUpdateSubmit();
                  setLoading(false);
                  setShowForm(false);
                }
              }}
              className={selectedBatchOption === "deleteOrders" ? "delete-button-small" : "submit-button-small"}
            >
              Confirm
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  const getSearchString = (item, config) => {
    let searchString = "";
    Object.keys(config).forEach((element) => {
      const { type, format = "hh:mm A MMM D" } = config[element];
      if (item[element]) {
        searchString += `${JSON.stringify(type === "date" ? getFormattedDate(item[element], format) : item[element])}%`;
      }
    });
    return searchString;
  };

  const listViewDataListControl = (groupby, dataList) => {
    switch (groupby) {
      case TAB_IDS.location:
        return dataList.map((each) => {
          const { id, name, lastUpdatedTime, warningThreshold, criticalThreshold, velocity } = each;
          const locationData = overallOverviewData.locations.find((each) => {
            return each.id === id;
          });

          let statusFlags = { displayText: "Healthy", color: "#00B200", value: 0 };
          if (locationData.totalTasks >= criticalThreshold) {
            statusFlags = { displayText: "Critical", color: "red", value: 2 };
          } else if (locationData.totalTasks >= warningThreshold) {
            statusFlags = { displayText: "Warning", color: "orange", value: 1 };
          }

          const dataObject = {
            ...each,
            id,
            name,
            numOfOrders: locationData.totalTasks,
            lastUpdatedTime,
            velocityRate: velocity,
            statusFlags: [statusFlags],
            numOfExpedited: locationData.expeditedTasks,
            numOfDelayed: locationData.delayedTasks
          };
          dataObject.searchString = getSearchString(dataObject, listViewConfig[groupby]);
          return dataObject;
        });

      case TAB_IDS.allParts:
        return dataList.map((each) => {
          const {
            due_date,
            orderId,
            orderNumber,
            id,
            name,
            quantity,
            startTime,
            vid,
            partLocation,
            represents,
            lastUpdatedTime,
            isChecked,
            partTypeIdentifier,
            status_flags = []
          } = each;
          const { status_flag } = represents;
          const statusFlags = getStatusFlags([...status_flag, ...status_flags], SolutionStatuses);
          const dataObject = {
            ...each,
            key: `${id}-${orderId}`,
            id,
            partId: id,
            orderId,
            partName: name,
            partTypeIdentifier,
            orderNumber,
            quantity,
            startTime,
            due_date,
            vid,
            partLocation: partLocation || "-",
            lastUpdatedTime,
            statusFlags,
            isChecked
          };
          dataObject.searchString = getSearchString(dataObject, listViewConfig[groupby]);
          return dataObject;
        });
      default:
        return dataList.map((each) => {
          const { status_flag } = each.represents;
          const statusFlags = getStatusFlags(status_flag, SolutionStatuses);
          statusFlags.sort((a, b) => {
            return a.id > b.id;
          });
          const dataObject = {
            ...each,
            id: each.id,
            name: each.name,
            vid: each.sensorProfile ? each.sensorProfile.vid : "-Not Tracked By Sensors-",
            lastKnownLocation: each.lastKnownLocationName || "-",
            lastUpdatedTime: each.lastUpdatedTime,
            startTime: each.startTime,
            due_date: each.represents.due_date,
            statusFlags,
            isChecked: each.isChecked
          };
          dataObject.searchString = getSearchString(dataObject, listViewConfig[TAB_IDS.allOrders]);
          return dataObject;
        });
    }
  };

  const splitIntoIdComponents = (id) => {
    const splitId = id.split(" ");
    const itemId = splitId[0];
    const attributeClicked = splitId[1];
    const orderId = splitId[3];

    return { itemId, attributeClicked, orderId };
  };

  const isNotInGroupView = () => {
    return !currentTab || currentTab === TAB_IDS.allParts || currentTab === TAB_IDS.allOrders;
  };

  const handleListViewClick = async ({ currentTarget }) => {
    if (isNotInGroupView()) {
      return;
    }

    const currentId = splitIntoIdComponents(currentTarget.id).itemId;
    const locationOverviewData = overallOverviewData.locations.find((each) => {
      return each.id === currentId;
    });

    await loadOrdersAtLocation(currentId);
    calculateOverviewData(locationOverviewData);

    breadcrumbsDataList.push({
      id: currentId,
      value: locationOverviewData.name
    });
    setBreadcrumbsDataList(breadcrumbsDataList);
  };

  const getListViewLinkDetails = ({ id, orderId }, header) => {
    const isNotGroupView = isNotInGroupView();
    let route;
    let isReplace = false;
    if (isNotGroupView) {
      if (currentTab === TAB_IDS.allParts) {
        if (header.id === PART_DETAILS_LINK_ATTRIBUTE) {
          route = `${history.location.pathname}/part/detail?itemId=${id}`;
        } else {
          route = `${history.location.pathname}/detail?itemId=${orderId}`;
        }
      } else {
        route = `${history.location.pathname}/detail?itemId=${id}`;
      }
    } else {
      route = `${history.location.pathname}${history.location.search}&id=${id}`;
      isReplace = true;
    }

    return { route, isReplace };
  };

  return (
    <>
      {showDisplayBanner && (
        <DisplayBanner
          bannerError={displayBannerError}
          bannerTitle={displayBannerError ? "Error" : "Success"}
          bannerMessage={displayBannerMessage}
          onCloseBanner={() => {
            setShowDisplayBanner(false);
          }}
        />
      )}
      <TrackPageComponent
        mainColor={mainColor}
        secondaryColor={secondaryColor}
        title="Work Orders"
        titleIcon={
          <OrderIcon
            width={25}
            height={25}
            style={{ color: mainColor }}
          />
        }
        overviewComponent={
          <Overview
            leftComponent={
              fullScreenMode ? (
                <OverviewDisplay
                  health={overviewData.health}
                  numOfOrders={overviewData.numOfOrders}
                  numOfExpedited={overviewData.numOfExpedited}
                />
              ) : (
                <BacklogGraphDisplay graphDataList={overviewData.graphDataList} />
              )
            }
            rightComponent={
              fullScreenMode ? (
                <MetricsDisplay
                  numOfDelayed={overviewData.numOfDelayed}
                  numOfOnTime={overviewData.numOfOnTime}
                  averageVelocity={overviewData.averageVelocity}
                  velocityLabel={overviewData.velocityLabel}
                />
              ) : (
                <div className={OrderOverviewComponentStyle.right_container}>
                  <OverviewDisplay
                    health={overviewData.health}
                    numOfOrders={overviewData.numOfOrders}
                    numOfExpedited={overviewData.numOfExpedited}
                  />
                  <MetricsDisplay
                    numOfDelayed={overviewData.numOfDelayed}
                    numOfOnTime={overviewData.numOfOnTime}
                    averageVelocity={overviewData.averageVelocity}
                    velocityLabel={overviewData.velocityLabel}
                  />
                </div>
              )
            }
          />
        }
        sidebarFeatureButtons={getSidebarFeatureButtons()}
        fullScreenMode={fullScreenMode}
        buttonTitle={!fullScreenMode && "+ Add Order"}
        buttonOnClick={
          isCreateWorkOrderV2Enabled
            ? () => {
                window.fcWidget.hide();
                setShowAddOrderModal(true);
              }
            : undefined
        }
        groupbyComponent={
          !listLoaded ? (
            <Skeleton count={3} />
          ) : (
            !fullScreenMode && (
              <GroupbySideBarComponent
                focusedTab={currentTab || focusedTab}
                tabStructure={groupbyStructure}
              />
            )
          )
        }
        breadcrumbsComponent={
          <BreadcrumbsComponent
            dataList={breadcrumbsDataList}
            onHomeClick={() => {
              handleTabClick(TAB_IDS.location, locationListData.locations);
              calculateOverviewData(overallOverviewData);
              setFocusedTab(null);
              history.replace(`${history.location.pathname}?group=location`);
              setBreadcrumbsDataList([]);
            }}
          />
        }
        dataViewComponent={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <TrackPageDataViewComponent
            dataList={currentDataList}
            defaultViewMode={defaultViewMode}
            sortSchema={getSortSchema()}
            filterFunc={filterFunction}
            fullScreenMode={fullScreenMode}
            setFullScreenModeFn={setFullScreenMode}
            fullScreenModeRefreshFn={onLoad}
            fullScreenModeRefreshInterval={refreshInterval}
            enableAutoRefresh={(fullScreenMode || enableAutoRefresh) && listLoaded}
            listViewFilterFunc={listViewFilterFunction} // needs to be refactor
            gridCardComponent={renderGridCard()}
            listViewStructure={headerStructure} // needs to be refactor
            listViewDataList={listViewDataListControl(currentTab, currentDataList)} // needs to be refactor
            getListViewLinkDetails={getListViewLinkDetails}
            // needs to be refactor
            handleListViewClick={handleListViewClick}
            multiSelectEnabled={
              batchUpdateOptions.length > 0 && currentTab !== TAB_IDS.location && currentTab !== TAB_IDS.allParts
            }
            onMultiClick={onMultiClick}
            resetPageOnLoad={
              !(batchUpdateOptions.length > 0 && currentTab !== TAB_IDS.location && currentTab !== TAB_IDS.allParts)
            }
            batchUpdateOptions={batchUpdateOptions}
            setFormComponent={setSelectedBatchUpdateOption}
            formComponent={getFormComponent()}
            disablePagination={disablePagination}
            loading={!listLoaded}
          />
        }
      />
      <ModalForm
        show={showForm}
        title={renderModalTitle()}
        body={renderModalBody()}
        footer={renderModalFooter()}
        centered
      />
      {selectedBatchOption === "printTags" && (
        <PrintTagsModal
          onCloseModal={(printAttempted, success, message, shouldReload) => {
            setSelectedBatchUpdateOption(undefined);

            if (printAttempted) {
              setShowDisplayBanner(true);
              setDisplayBannerError(!success);
              setDisplayBannerMessage(message);
            }
            if (shouldReload) {
              batchUpdateFinalAction();
            }
          }}
          selectedItems={currentDataList.filter((element) => {
            return element.isChecked;
          })}
          modalTitle="Reprint Tags"
          appId={APP_ID}
        />
      )}
      {showAddOrderModal && (
        <CreateWorkOrderModal
          onCloseClick={async (submission) => {
            window.fcWidget.show();
            setShowAddOrderModal(false);
            if (submission) {
              setListLoaded(false);
              const queryPayload = queryString.parse(history.location.search);
              if (queryPayload.group === TAB_IDS.location) {
                processQueryPayload(queryPayload);
              } else {
                loadAllOrders();
              }
              loadOverviewContent();
            }
          }}
        />
      )}
    </>
  );
};
export default withRouter(OrderTrackPageFeature);