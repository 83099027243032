import React, { useState, useEffect, useRef, useMemo, Fragment } from "react";
import Style from "./CreateWorkOrderModal.module.css";
import "./CreateWorkOrderModal.css";
import { ModalForm } from "components/modal-form";
import { CloseRounded, WarningOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import AutoSizeTextArea from "components/AutoSizeTextArea/AutoSizeTextArea";
import SearchDropdown from "components/SearchDropdown/SearchDropdown";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import { BlobProvider } from "@react-pdf/renderer";
import {
  useBOMManagementContext,
  BOMManagementContextProvider
} from "../../context/BOMManagementContext/BOMManagementContext";
import useImportTagPrinterScripts from "../../hooks/use-import-tag-printer-scripts";
import BOMCreateOrderOperationTable from "../bom-management-feature/BOMCreateOrderOperationTable";
import BOMCreateOrderInputPartTable from "../bom-management-feature/BOMCreateOrderInputPartTable";
import WorkOrderPDF from "../../components/PDF/work-order/WorkOrderPDF";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { useXemelgoAppsyncClient } from "../../services/xemelgo-appsync-service";
import { DatePicker, DateTimePicker } from "../../components/DateTimePicker/DateTimePicker";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import { getLogo, getCurrentTimestamp } from "../../common/Utilities";
import DisplayBanner from "../../components/display-banner/DisplayBanner";
import { Modal } from "react-bootstrap";
import { generateSensorProfileVidUsingInput } from "./utils/AddOrderUtils";
import getInitialValue from "./utils/form-utils/get-initial-value";
import getCreateValue from "./utils/form-utils/get-create-value";
import { bartenderService } from "../../services/bartender-service/BartenderService";
import useAutoGenerateWorkOrderIdentifiers from "./hooks/use-auto-generate-work-order-identifiers/useAutoGenerateWorkOrderIdentifiers";
import { GroupOnboardingWorkOrderSection } from "./features/group-onboarding-work-order-section/GroupOnboardingWorkOrderSection";
import {
  WORK_ORDER_ONBOARDING_V2,
  WORK_ORDER_ONBOARDING_V2_STEPS
} from "../../constants/mixpanel-constant/workOrderOnboardingV2";
import useMixpanelContext from "../../context/mixpanel-context";
import PrintSection from "../../components/print-section";

const APP_ID = "order";
const FEATURE_ID = "addOrder";
const GROUP_ONBOARDING_KEY = "rowCount";
const PRINT_LATER_KEY = "print_later";

const oneMinute = 60 * 1000;

const CreateWorkOrderModalHeader = ({ onCloseClick }) => {
  return (
    <div className={`${Style.header_container} ${Style.flex_column}`}>
      <button
        className={`${Style.header_close_button} ${Style.flex_row}`}
        onClick={onCloseClick}
      >
        <CloseRounded className={Style.header_close_button_icon} />
      </button>
      <div className={`${Style.header_title_container} ${Style.flex_row}`}>
        <div className={Style.header_title_icon_container}>
          <img
            src={require("../../img/work-order_icon_green.png")}
            width="50x"
            height="40px"
            style={{ marginBottom: "2px" }}
          />
        </div>
        <p>New Work Order</p>
      </div>
    </div>
  );
};

const CreateWorkOrderModalFooter = ({ onDiscardClick, onSaveClick, isCreateButtonDisabled }) => {
  return (
    <div className={`${Style.footer_container} ${Style.flex_row}`}>
      <button
        onClick={onDiscardClick}
        tabIndex="-1"
      >
        Discard
      </button>
      <button
        disabled={isCreateButtonDisabled}
        onClick={onSaveClick}
      >
        Create
      </button>
    </div>
  );
};

const getItemTypeOfRank = (inputPartList, inputPartRank) => {
  const selectedItemId = Object.keys(inputPartList).find((item) => {
    return inputPartList[item].rank === inputPartRank;
  });
  const selectedItem = inputPartList[selectedItemId];
  return selectedItem;
};

const printerInfo = {
  name: { hiddenOnInfoCard: true },
  message: { label: "Status" },
  id: { label: "IP Address" }
};

const CreateWorkOrderModal = ({ onCloseClick }) => {
  const inputPartTableRef = useRef();
  const operationTableRef = useRef();
  const PDFDownloadButtonRef = useRef(null);
  const groupOnboardingSectionRef = useRef(null);

  const configProvider = useAppConfigProvider(APP_ID);
  const history = useHistory();
  const xemelgoClient = useXemelgoClient();
  const xemelgoAppsyncClient = useXemelgoAppsyncClient();
  const [bomClient] = useState(xemelgoClient.getBomClient());
  const [workOrderClient] = useState(xemelgoClient.getWorkOrderClient());
  const [appsyncWorkOrderClient] = useState(xemelgoAppsyncClient.getWorkOrderClient());
  const [itemClient] = useState(xemelgoClient.getItemClient());
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState();
  const [isBannerError, setIsBannerError] = useState(true);
  const { additionalInformation, setAdditionalInformation, setInputPartGroupInfo, setOperationInfo } =
    useBOMManagementContext();

  const [inputPartGroupInfoOriginal, setInputPartGroupInfoOriginal] = useState([]);

  const [attributeGroup, setAttributeGroup] = useState([]);
  const [autoGenerateSensorProfile, setAutoGenerateSensorProfile] = useState(false);
  const [groupOnboardingEnabled, setGroupOnboardingEnabled] = useState(false);
  const [additionalFeatures, setAdditionalFeatures] = useState([]);
  const [comments, setComments] = useState("");
  const [bomIdMap, setBomIdMap] = useState({});
  const [isBomEnabled, setIsBomEnabled] = useState(true);
  const [isWorkOrderSectionLoading, setIsWorkOrderSectionLoading] = useState(true);
  const [isInputPartTableLoading, setIsInputPartTableLoading] = useState(true);
  const [isOperationTableLoading, setIsOperationTableLoading] = useState(true);
  const [selectedItemType, setSelectedItemType] = useState({});
  const [autoGenerate, setAutoGenerate] = useState(false);
  const [isPDFLoaded, setIsPDFLoaded] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [showQuantityCheckModal, setShowQuantityCheckModal] = useState(false);
  const [quantityCheckNote, setQuantityCheckNote] = useState("");
  const [printLaterLocationId, setPrintLaterLocationId] = useState("");
  const [bomSectionData, setBomSectionData] = useState({
    due_date: undefined,
    start_date: undefined,
    estimated_date: undefined,
    quantity: "",
    comments: ""
  });
  const [bomSectionDataErrorMap, setBomSectionDataErrorMap] = useState({
    quantity: {
      error: false,
      errorMessage: ""
    }
  });
  const [newInputGroupInfo, setNewInputGroupInfo] = useState({});

  const [printers, setPrinters] = useState([]);
  const [printerSectionLoading, setPrinterSectionLoading] = useState(false);
  const [tagConfig, setTagConfig] = useState({});
  const [useV2API, setUseV2API] = useState(false);
  const [customTemplate, setCustomTemplate] = useState(null);
  const [printLater, setPrintLater] = useState(false);

  const [sensorProfileVidCharLimit, setSensorProfileVidCharLimit] = useState(null);

  const { sendMixPanelEvent } = useMixpanelContext();

  const { orderBartenderConfiguration = {} } = configProvider.getModelMap();

  const { generate: generateWorkOrderIdentifiers } = useAutoGenerateWorkOrderIdentifiers();

  const {
    endpoint: bartenderUrl,
    propertiesToHeaderMap = {},
    printerName,
    templateFilePath
  } = orderBartenderConfiguration.def || {};

  const { printTag: bartenderPrint, listAvailablePrinters: bartenderListAvailablePrinters } =
    bartenderService(bartenderUrl);

  const {
    queryPrinters,
    isPrinterScriptsReady,
    printTagV1,
    printCustomTag,
    registerPrinter,
    setSelectedPrinterInfo,
    selectedPrinterInfo,
    stopGetPrinterStatus
  } = useImportTagPrinterScripts();

  const onSelectPrinter = async (printer) => {
    if (!printer) {
      setSelectedPrinterInfo({});
      await registerPrinter({});

      return;
    }
    setPrinterSectionLoading(true);

    // TODO: temporary for default bartender printer
    if (bartenderUrl) {
      setSelectedPrinterInfo({ ...printerInfo, name: { ...printerInfo.name, label: printer.label } });
      setPrinterSectionLoading(false);
      return;
    }

    setPrinterSectionLoading(false);
    await registerPrinter(printer);
  };

  const queryBartenderPrinters = async () => {
    setPrinterSectionLoading(true);

    // TODO: figure out the way to get the list of available printers
    const availablePrinters = await bartenderListAvailablePrinters();

    const defaultPrinter = { id: "default_printer", name: "default_printer", label: printerName };

    setPrinters(availablePrinters || [defaultPrinter]);
    onSelectPrinter(defaultPrinter);
    setPrinterSectionLoading(false);
  };

  useEffect(() => {
    sendMixPanelEvent(WORK_ORDER_ONBOARDING_V2, WORK_ORDER_ONBOARDING_V2_STEPS.ENTRY);
  }, []);

  useEffect(() => {
    if (!isWorkOrderSectionLoading && isPrinterScriptsReady && additionalFeatures.includes("printerEnabled")) {
      if (bartenderUrl) {
        queryBartenderPrinters();
      } else if (!selectedPrinterInfo.id) {
        const setError = () => {
          setShowBanner(true);
          setIsBannerError(true);
        };
        queryPrinters(setPrinterSectionLoading, setPrinters, onSelectPrinter, setError, setBannerMessage);
      }
    }
  }, [isWorkOrderSectionLoading, isPrinterScriptsReady, additionalFeatures]);

  const generateNewIdentifiers = async (payload) => {
    const response = await appsyncWorkOrderClient.generateIdentifiers(payload.groupName, payload.quantity);
    const { data, errors } = response;
    const { generateIdentifiers } = data;
    if (errors) {
      const [error] = errors;
      throw error;
    }

    return generateIdentifiers.identifiers;
  };

  const autoGenerateIdentifiers = async (payload) => {
    let result;
    try {
      result = await generateNewIdentifiers(payload);
    } catch (error) {
      if (error.errorType === "ReferenceError") {
        const groupResult = await appsyncWorkOrderClient.createIdentifierGenerationGroup(
          payload.groupName,
          payload.prefix,
          payload.identifierStartingValue,
          payload.identifierEndingValue
        );
        const { error } = groupResult;
        if (error) {
          throw error;
        }
        result = await generateNewIdentifiers(payload);
      } else {
        throw error;
      }
    }
    return result;
  };

  const onLoad = async () => {
    const {
      attributeGroups = [],
      bomEnabled = false,
      autoGenerateEnabled = false,
      autoGenerateSensorProfile: newAutoGenerateSensorProfile = false,
      additionalFeatures: newAdditionalFeatures = [],
      groupOnboardingAttributes: newGroupOnboardingAttributes = {},
      tagConfig: newTagConfig = {},
      sensorProfileVidCharLimit: newSensorProfileVidCharLimit = 2048,
      useV2 = false,
      customTemplate: newCustomTemplate = "",
      printLaterLocationId: newPrintLaterLocationId = ""
    } = configProvider.getValue(FEATURE_ID, "object") || {};
    setAutoGenerate(autoGenerateEnabled);
    setAutoGenerateSensorProfile(newAutoGenerateSensorProfile);
    setIsBomEnabled(bomEnabled);
    setSensorProfileVidCharLimit(newSensorProfileVidCharLimit);
    const newAttributeGroup = [];

    for (let attributeGroupIndex = 0; attributeGroupIndex < attributeGroups.length; attributeGroupIndex++) {
      const attributeMap = {};
      const attributeKeys = Object.keys(attributeGroups[attributeGroupIndex]);
      for (let attributeKeyIndex = 0; attributeKeyIndex < attributeKeys.length; attributeKeyIndex++) {
        const currentAttribute = _.cloneDeep(attributeGroups[attributeGroupIndex][attributeKeys[attributeKeyIndex]]);
        const { type } = currentAttribute;
        currentAttribute.value = await getInitialValue(type, currentAttribute, xemelgoClient);
        attributeMap[attributeKeys[attributeKeyIndex]] = currentAttribute;
      }
      newAttributeGroup.push(attributeMap);
    }

    setPrintLaterLocationId(newPrintLaterLocationId);
    setGroupOnboardingEnabled(Object.keys(newGroupOnboardingAttributes).length > 0);

    setIsWorkOrderSectionLoading(false);
    setIsInputPartTableLoading(false);
    setIsOperationTableLoading(false);
    setAttributeGroup(newAttributeGroup);
    setAdditionalFeatures(newAdditionalFeatures);
    setTagConfig(newTagConfig);
    setUseV2API(useV2);
    setCustomTemplate(newCustomTemplate);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const fetchBOMInformation = async (itemTypeId) => {
    setIsInputPartTableLoading(true);
    setIsOperationTableLoading(true);
    setOperationInfo([]);
    setInputPartGroupInfo([]);
    const newBomIdMap = await bomClient.getBomIdsMapUsingItemTypeId(itemTypeId);
    if (!newBomIdMap.bomId) {
      setIsInputPartTableLoading(false);
      setIsOperationTableLoading(false);
    } else {
      const newAdditionalInformation = await bomClient.getAdditionalInfoByBomId(newBomIdMap.bomId);
      setAdditionalInformation(newAdditionalInformation);
    }
    setBomIdMap({ ...newBomIdMap });
  };

  const getItemTypeQuantity = async (itemTypeId) => {
    let itemTypeTotalQuantity = 0;
    const associateItems = await itemClient.queryActiveItemsDetails(
      itemTypeId, // ofType
      null, // ofLocation
      null, // ofCLass
      undefined, // ignoreExisted
      ["id"], // trackingSEssionFields
      ["id"], // lastKnownLocationFields
      ["id"], // sensorProfileFields
      ["id"], // itemTyeFields
      ["pounds_ts", "is_consumed"], // itemFields
      ["id"] // taskFields
    );
    associateItems.forEach((associateItem) => {
      const { item } = associateItem;
      // only include item that is not consumed
      if (!item.is_consumed) {
        itemTypeTotalQuantity += item.pounds_ts;
      }
    });
    return itemTypeTotalQuantity;
  };

  const fetchInputParts = async () => {
    setIsInputPartTableLoading(true);
    const inputPartGroupList = await bomClient.getInputPartsByBomId(bomIdMap.bomId);

    for (const eachInputGroup of inputPartGroupList) {
      for (const eachItemType of eachInputGroup.itemTypes) {
        eachItemType.onHandQuantity = await getItemTypeQuantity(eachItemType.itemTypeId);
      }
    }

    setInputPartGroupInfoOriginal(_.cloneDeep(inputPartGroupList));

    inputPartGroupList.forEach((eachInputGroup) => {
      eachInputGroup.itemTypes.forEach((eachItemType) => {
        eachItemType.quantity =
          bomSectionData.quantity * 1 ? bomSectionData.quantity * 1 * eachItemType.quantity : eachItemType.quantity;
      });
    });
    setInputPartGroupInfo(inputPartGroupList);
    setIsInputPartTableLoading(false);
  };

  const fetchOperations = async () => {
    setIsOperationTableLoading(true);
    const newOperationInfo = await bomClient.getRoutingsByBomId(bomIdMap.bomId);
    setOperationInfo(newOperationInfo);
    setIsOperationTableLoading(false);
  };

  useEffect(() => {
    if (selectedItemType.id && isBomEnabled) {
      fetchBOMInformation(selectedItemType.id);
    }
  }, [selectedItemType]);

  useEffect(() => {
    if (bomIdMap?.bomId) {
      fetchInputParts();
      fetchOperations();
    }
  }, [bomIdMap]);

  // update quantity and comments of BOM Section Data after fetching bom information
  useEffect(() => {
    bomSectionData.quantity = additionalInformation.quantity?.toString() || "";
    bomSectionData.comments = additionalInformation.description || "";
    setBomSectionData({ ...bomSectionData });
  }, [additionalInformation]);

  const validateWorkOrderFields = () => {
    let hasError = false;
    attributeGroup.forEach((eachAttributeGroup) => {
      Object.keys(eachAttributeGroup).forEach((eachAttributeId) => {
        const eachAttribute = eachAttributeGroup[eachAttributeId];
        const { required, value, type, label } = eachAttribute;
        if (required) {
          const errorMessage = `${label} is a required field.`;
          switch (type) {
            case "api-dropdown":
            case "dropdown":
              // check to see if selected object is empty
              if (Object.keys(value).length < 3) {
                eachAttribute.error = true;
                eachAttribute.errorMessage = errorMessage;
                hasError = true;
              }
              break;
            default:
              if (!value) {
                eachAttribute.error = true;
                eachAttribute.errorMessage = errorMessage;
                hasError = true;
              }
              break;
          }
        }
      });
    });
    if (hasError) {
      setAttributeGroup([...attributeGroup]);
    }
    return !hasError;
  };

  const validateBOMFields = () => {
    let hasError = false;
    if (!(bomSectionData.quantity * 1)) {
      bomSectionDataErrorMap.quantity.error = true;
      bomSectionDataErrorMap.quantity.errorMessage = `Qty is a required field`;
      hasError = true;
    } else if (additionalInformation.quantity && bomSectionData.quantity * 1 < additionalInformation.quantity) {
      bomSectionDataErrorMap.quantity.error = true;
      bomSectionDataErrorMap.quantity.errorMessage = `Minimum qty is ${additionalInformation.quantity}`;
      hasError = true;
    }
    if (hasError) {
      setBomSectionDataErrorMap({
        ...bomSectionDataErrorMap
      });
    }
    return !hasError;
  };

  const overallCycleTime = useMemo(() => {
    return (bomSectionData.quantity * 1 * (selectedItemType.cycle_time_ts * 1)).toFixed(2) * 1;
  }, [bomSectionData.quantity, selectedItemType]);

  const estimatedDateTime = useMemo(() => {
    return overallCycleTime ? bomSectionData.start_date + overallCycleTime * 1000 * 60 * 60 : null;
  }, [overallCycleTime, bomSectionData.start_date]);

  const inSufficientQuantityParts = useMemo(() => {
    const inSufficientQuantityParts = [];
    Object.keys(newInputGroupInfo).forEach((inputPart) => {
      const inputParts = newInputGroupInfo[inputPart];
      const inputPartRank = inputParts.rank;
      delete inputParts.rank;
      const inputItemOfRank = getItemTypeOfRank(inputParts, inputPartRank);
      if (inputItemOfRank?.quantity > inputItemOfRank?.onHandQuantity) {
        inSufficientQuantityParts.push(inputItemOfRank?.itemTypeIdentifier);
      }
    });
    return inSufficientQuantityParts;
  }, [newInputGroupInfo]);

  const renderWorkOrderSection = () => {
    return (
      <div
        className={`${Style.work_order_information} ${Style.flex_column}`}
        style={!isBomEnabled ? { flex: 1 } : {}}
      >
        <p>Work order information</p>
        {isWorkOrderSectionLoading ? (
          <div className={`${Style.input_group_list} ${Style.grid}`}>
            {[...Array(6)].map((_, index) => {
              return (
                <div
                  key={index}
                  className={`${Style.flex_column}`}
                >
                  <Skeleton height={28} />
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {attributeGroup.map((eachAttributeGroup, groupIndex) => {
              return (
                <div
                  key={groupIndex}
                  className={`${Style.input_group_list} ${Style.grid}`}
                >
                  {Object.keys(eachAttributeGroup)
                    .sort((a, b) => {
                      const { index: aIndex = 0 } = eachAttributeGroup[a];
                      const { index: bIndex = 0 } = eachAttributeGroup[b];
                      return aIndex - bIndex;
                    })
                    .filter((key) => {
                      return !(printLater && key === "onboardingLocation");
                    })
                    .map((eachAttributeKey, secondaryGroupIndex) => {
                      const {
                        label,
                        value,
                        editable,
                        type,
                        options,
                        dependentOn,
                        apiKey,
                        required,
                        error,
                        errorMessage,
                        hidden = false
                      } = eachAttributeGroup[eachAttributeKey];

                      if (hidden) {
                        return null;
                      }

                      return (
                        <div
                          key={label}
                          className={`${Style.flex_column}`}
                        >
                          <div className={Style.flex_row}>
                            <p className={`${Style.attribute_label}`}>{`${label}`}</p>
                            {required && <p className={`${Style.attribute_label} ${Style.label_asterisk}`}>*</p>}
                          </div>
                          {(() => {
                            switch (type) {
                              case "date":
                                return (
                                  <DatePicker
                                    value={
                                      dependentOn
                                        ? attributeGroup[groupIndex][dependentOn]?.value[eachAttributeKey] || undefined
                                        : value
                                    }
                                    error={error}
                                    errorMessage={errorMessage}
                                    readOnly={!!dependentOn || !editable}
                                    onTimeChange={(timestamp) => {
                                      if (timestamp) {
                                        attributeGroup[groupIndex][eachAttributeKey].error = false;
                                        attributeGroup[groupIndex][eachAttributeKey].errorMessage = "";
                                      }
                                      attributeGroup[groupIndex][eachAttributeKey].value = timestamp;
                                      setAttributeGroup([...attributeGroup]);
                                    }}
                                  />
                                );

                              case "datetime":
                                return (
                                  <DateTimePicker
                                    value={
                                      dependentOn
                                        ? attributeGroup[groupIndex][dependentOn]?.value[eachAttributeKey] || undefined
                                        : value
                                    }
                                    error={error}
                                    errorMessage={errorMessage}
                                    readOnly={!!dependentOn || !editable}
                                    onTimeChange={(timestamp) => {
                                      if (timestamp) {
                                        attributeGroup[groupIndex][eachAttributeKey].error = false;
                                        attributeGroup[groupIndex][eachAttributeKey].errorMessage = "";
                                      }
                                      attributeGroup[groupIndex][eachAttributeKey].value = timestamp;
                                      setAttributeGroup([...attributeGroup]);
                                    }}
                                  />
                                );
                              case "api-dropdown":
                                return (
                                  <SearchDropdown
                                    error={error}
                                    errorMessage={errorMessage}
                                    options={options}
                                    selectedItem={value}
                                    onItemSelected={(newItem) => {
                                      if (newItem) {
                                        attributeGroup[groupIndex][eachAttributeKey].error = false;
                                        attributeGroup[groupIndex][eachAttributeKey].errorMessage = "";
                                      }
                                      attributeGroup[groupIndex][eachAttributeKey].value = newItem;
                                      setAttributeGroup([...attributeGroup]);
                                      if (apiKey === "getItemTypes") {
                                        setSelectedItemType(newItem);
                                      }
                                    }}
                                  />
                                );
                              case "dropdown":
                                return (
                                  <SearchDropdown
                                    error={error}
                                    errorMessage={errorMessage}
                                    options={options}
                                    selectedItem={value}
                                    onItemSelected={(newItem) => {
                                      if (newItem) {
                                        attributeGroup[groupIndex][eachAttributeKey].error = false;
                                        attributeGroup[groupIndex][eachAttributeKey].errorMessage = "";
                                      }
                                      attributeGroup[groupIndex][eachAttributeKey].value = newItem;
                                      setAttributeGroup([...attributeGroup]);
                                    }}
                                  />
                                );
                              default:
                                return (
                                  <AutoSizeTextArea
                                    autoFocus={groupIndex == 0 && secondaryGroupIndex == 0}
                                    error={error}
                                    errorMessage={errorMessage}
                                    readOnly={!!dependentOn || !editable}
                                    backgroundColor={(dependentOn || !editable) && "#fbfafa"}
                                    value={
                                      dependentOn
                                        ? attributeGroup[groupIndex][dependentOn]?.value[eachAttributeKey] || "--"
                                        : value
                                    }
                                    onChangeText={(newText) => {
                                      if (newText) {
                                        attributeGroup[groupIndex][eachAttributeKey].error = false;
                                        attributeGroup[groupIndex][eachAttributeKey].errorMessage = "";
                                      }
                                      attributeGroup[groupIndex][eachAttributeKey].value = newText;
                                      setAttributeGroup([...attributeGroup]);
                                    }}
                                  />
                                );
                            }
                          })()}
                        </div>
                      );
                    })}
                </div>
              );
            })}
            {isBomEnabled && (
              <>
                <div className={`${Style.input_group_list} ${Style.grid}`}>
                  <div className={`${Style.flex_column}`}>
                    <div className={`${Style.flex_row}`}>
                      <p className={`${Style.attribute_label}`}>Qty</p>
                      <p className={`${Style.attribute_label} ${Style.label_asterisk}`}>*</p>
                    </div>

                    {isInputPartTableLoading ? (
                      <Skeleton height={40} />
                    ) : (
                      <AutoSizeTextArea
                        numberOnly
                        error={bomSectionDataErrorMap.quantity.error}
                        errorMessage={bomSectionDataErrorMap.quantity.errorMessage}
                        value={bomSectionData.quantity}
                        onChangeText={(newText) => {
                          if (newText) {
                            bomSectionDataErrorMap.quantity.error = false;
                            bomSectionDataErrorMap.quantity.errorMessage = "";
                          }
                          bomSectionData.quantity = newText;
                          const inputPartGroupList = _.cloneDeep(inputPartGroupInfoOriginal);
                          inputPartGroupList.forEach((eachInputGroup) => {
                            eachInputGroup.itemTypes.forEach((eachItemType) => {
                              eachItemType.quantity =
                                newText * 1 ? newText * 1 * eachItemType.quantity : eachItemType.quantity;
                            });
                          });
                          setInputPartGroupInfo([...inputPartGroupList]);
                          setBomSectionData({
                            ...bomSectionData
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className={`${Style.input_group_list} ${Style.grid}`}>
                  <div className={`${Style.flex_column}`}>
                    <p className={`${Style.attribute_label}`}>Part Cycle Time (Hours)</p>
                    <AutoSizeTextArea
                      backgroundColor="#fbfafa"
                      readOnly
                      value={selectedItemType?.cycle_time_ts || "--"}
                    />
                  </div>
                  <div className={`${Style.flex_column}`}>
                    <p className={`${Style.attribute_label}`}>Overall Cycle Time (Hours)</p>
                    <AutoSizeTextArea
                      backgroundColor="#fbfafa"
                      readOnly
                      value={overallCycleTime || "--"}
                    />
                  </div>
                </div>
                <div className={`${Style.input_group_list} ${Style.grid}`}>
                  <div className={`${Style.flex_column}`}>
                    <p className={`${Style.attribute_label}`}>Due Date</p>
                    <DatePicker
                      value={bomSectionData.due_date}
                      onTimeChange={(timestamp) => {
                        bomSectionData.due_date = timestamp;
                        setBomSectionData({
                          ...bomSectionData
                        });
                      }}
                    />
                  </div>
                  <div className={`${Style.flex_column}`}>
                    <p className={`${Style.attribute_label}`}>Start Date & Time</p>
                    <DateTimePicker
                      value={bomSectionData.start_date}
                      onTimeChange={(timestamp) => {
                        bomSectionData.start_date = timestamp;
                        setBomSectionData({
                          ...bomSectionData
                        });
                      }}
                    />
                  </div>
                  <div className={`${Style.flex_column}`}>
                    <p className={`${Style.attribute_label}`}>Estimated Date & Time</p>
                    <DateTimePicker
                      readOnly
                      value={estimatedDateTime}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const onDiscardClick = () => {
    if (onCloseClick) {
      onCloseClick();
    } else {
      history.goBack();
    }
  };

  const bartenderPrintTag = async (workOrderToPrintObject) => {
    const bartenderPayload = {};
    Object.keys(propertiesToHeaderMap).forEach((property) => {
      switch (property) {
        case "format_name":
          bartenderPayload[propertiesToHeaderMap[property]] = templateFilePath;
          break;
        case "printer_name":
          bartenderPayload[propertiesToHeaderMap[property]] = selectedPrinterInfo.name.label;
          break;
        case "quantity":
          bartenderPayload[propertiesToHeaderMap[property]] = 1;
          break;
        default:
          bartenderPayload[propertiesToHeaderMap[property]] = workOrderToPrintObject[property];
          break;
      }
    });
    try {
      await bartenderPrint(bartenderPayload);
    } catch (e) {
      throw "Fail to print";
    }
  };

  const clearFormInput = () => {
    const newAttributeGroup = attributeGroup.reduce((acc, eachAttributeGroup) => {
      const newEachAttributeGroup = {};
      Object.keys(eachAttributeGroup).forEach((eachAttributeKey) => {
        const eachAttribute = eachAttributeGroup[eachAttributeKey];
        const { isPersistedAfterFormReload = false } = eachAttribute;
        const newValue = isPersistedAfterFormReload ? eachAttribute.value : eachAttribute.defaultValue || "";
        newEachAttributeGroup[eachAttributeKey] = {
          ...eachAttribute,
          value: newValue
        };
      });
      acc.push(newEachAttributeGroup);
      return acc;
    }, []);
    setAttributeGroup(newAttributeGroup);
  };

  const onCreateClick = async () => {
    let canSubmit = true;
    setIsBannerError(true); // default the banner to be error until we know it is success
    setShowBanner(false);
    setIsWorkOrderSectionLoading(true);
    setIsInputPartTableLoading(true);
    setIsOperationTableLoading(true);
    canSubmit = validateWorkOrderFields();
    stopGetPrinterStatus();

    const { groupOnboardingAttributes = {}, groupOnboardingRows = {} } =
      groupOnboardingSectionRef.current?.getFormData() || {};

    if (isBomEnabled) {
      canSubmit = canSubmit && validateBOMFields();
    } else if (Object.keys(groupOnboardingAttributes).length > 0) {
      canSubmit = canSubmit && groupOnboardingSectionRef.current.validateGroupOnboardingFields();
    }

    if (additionalFeatures.includes("printerEnabled") && Object.keys(selectedPrinterInfo).length === 0 && !printLater) {
      canSubmit = false;
      setShowBanner(true);
      setBannerMessage("Printer not selected!");
    }

    const payload = {
      task_fields: {
        category: "Tracked Work Order"
      },
      item_fields: {}
    };
    if (canSubmit) {
      let identifier = null;
      let tracker_serial = null;
      const identifierAttribute = attributeGroup.find((group) => {
        return !!group?.identifier;
      });
      identifier = identifierAttribute?.identifier?.value;
      if (autoGenerate && !printLater) {
        const autoGenerateErrorMessage = "Auto-generate work order number failed. Please try again.";
        if (Object.keys(groupOnboardingAttributes).length > 0 && !isBomEnabled) {
          try {
            identifier = await generateWorkOrderIdentifiers(
              identifierAttribute?.identifier?.value,
              groupOnboardingRows.length,
              "-"
            );
          } catch (error) {
            console.error(error);
            setShowBanner(true);
            setBannerMessage(autoGenerateErrorMessage);
            return;
          }
        } else {
          const autoGeneratePayload = {
            groupName: identifierAttribute?.identifier?.value || "Traveller",
            quantity: 1,
            prefix: identifierAttribute?.identifier?.value || null,
            identifierStartingValue: identifierAttribute?.identifier?.value ? 1 : 100000,
            identifierEndingValue: 999999
          };
          try {
            [identifier] = await autoGenerateIdentifiers(autoGeneratePayload);
            if (identifierAttribute?.identifier?.value) {
              identifier = `${identifierAttribute?.identifier?.value}-${parseInt(identifier).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false
              })}`;
            }
          } catch (error) {
            setShowBanner(true);
            setBannerMessage(autoGenerateErrorMessage);
            return;
          }
        }
      }

      const onboardingLocationAttribute = attributeGroup.find((group) => {
        return !!group?.onboardingLocation;
      });
      const onboardingLocationId = onboardingLocationAttribute?.onboardingLocation.value?.id;

      attributeGroup.forEach((eachGroupAttributes) => {
        Object.keys(eachGroupAttributes)
          .filter((eachAttributeKey) => {
            return !eachGroupAttributes[eachAttributeKey].skipForCreation;
          })
          .forEach((eachAttributeKey) => {
            const { propertyFor } = eachGroupAttributes[eachAttributeKey];
            const finalValue = getCreateValue(eachGroupAttributes[eachAttributeKey]);
            if (eachAttributeKey === "output_items") {
              payload[eachAttributeKey] = [
                {
                  itemType_identifier: finalValue
                }
              ];
            } else {
              payload[propertyFor === "item" ? "item_fields" : "task_fields"][eachAttributeKey] = finalValue;
            }
          });
      });

      autoGenerate && (payload.identifier = identifier);
      let createdWO = null;
      additionalFeatures.includes("commentsEnabled") && (payload.task_fields.comments = comments);

      if (isBomEnabled) {
        payload.output_items[0].quantity = (bomSectionData.quantity || 1) * 1;
        payload.output_items[0].unit = additionalInformation.unit || "";
        payload.output_items[0].id = selectedItemType.id;
        payload.task_fields = {
          ...payload.task_fields,
          due_date: bomSectionData.due_date,
          start_date: bomSectionData.start_date,
          comments: bomSectionData.comments,
          status: "Planned",
          ...payload.ts_prop
        };

        const inputPartTableData = inputPartTableRef.current.onSaveClick();

        payload.input_items = Object.keys(inputPartTableData)
          .filter((eachInputGroupId) => {
            return eachInputGroupId !== "rank";
          })
          .map((eachInputGroupId) => {
            const { rank } = inputPartTableData[eachInputGroupId];
            const [inputPartId] = Object.keys(inputPartTableData[eachInputGroupId]).filter((eachInputPartId) => {
              return (
                eachInputPartId !== "createNew" && inputPartTableData[eachInputGroupId][eachInputPartId].rank === rank // only show the item type matches the rank
              );
            });

            const { id, itemTypeId, quantity, onHandQuantity, unit } =
              inputPartTableData[eachInputGroupId][inputPartId];
            return {
              id,
              itemTypeId,
              quantity,
              onHandQuantity,
              unit,
              rank
            };
          });
        createdWO = await workOrderClient.createWorkOrderV2(
          payload.identifier,
          payload.task_fields,
          bomIdMap.bomId,
          bomIdMap.routingId,
          payload.output_items[0].id,
          payload.output_items[0].quantity,
          payload.output_items[0].unit
        );
        if (createdWO?.createWorkOrder?.id) {
          const { taskBillOfMaterialsId, inputGroupIds } = await workOrderClient.getTaskInputGroupIdsByTaskId(
            createdWO.createWorkOrder.id
          );
          await workOrderClient.createTravellerForTask(
            createdWO.createWorkOrder.identifier,
            createdWO.createWorkOrder.id
          );
          await workOrderClient.updateTaskInputGroups(
            createdWO.createWorkOrder.id,
            taskBillOfMaterialsId,
            inputGroupIds,
            payload.input_items
          );
          if (additionalInformation.description?.trim() !== bomSectionData.comments?.trim()) {
            await workOrderClient.updateTaskBillOfMaterials(createdWO.createWorkOrder.id, taskBillOfMaterialsId, {
              description: (bomSectionData.comments && bomSectionData.comments?.trim()) || null
            });
          }
        }
      } else if (printLater) {
        await workOrderClient.createWorkOrderBatch(
          [
            {
              identifier: payload.identifier,
              item: { ...payload.item_fields },
              task: { ...payload.task_fields }
            }
          ],
          printLaterLocationId
        );
      } else if (Array.isArray(payload.identifier)) {
        Object.keys(groupOnboardingAttributes).forEach((eachAttributeKey) => {
          if (eachAttributeKey === PRINT_LATER_KEY) {
            return;
          }

          const { propertyFor } = groupOnboardingAttributes[eachAttributeKey];
          const finalValue = getCreateValue(groupOnboardingAttributes[eachAttributeKey]);
          if (eachAttributeKey === GROUP_ONBOARDING_KEY) {
            return;
          }

          payload[propertyFor === "item" ? "item_fields" : "task_fields"][eachAttributeKey] = finalValue;
        });
        const workOrders = [];
        let spVids = [];

        if (autoGenerateSensorProfile) {
          // generate SP vids for the list of identifiers (payload.identifier is an array of strings)
          spVids = generateSensorProfileVidUsingInput(payload.identifier, getCurrentTimestamp());
        }
        for (let index = 0; index < payload.identifier.length; index++) {
          const currentIdentifier = payload.identifier[index];
          const currentRow = groupOnboardingRows[index];
          Object.keys(currentRow).forEach((eachAttributeKey) => {
            const { propertyFor } = currentRow[eachAttributeKey];
            const finalValue = getCreateValue(currentRow[eachAttributeKey]);

            payload[propertyFor === "item" ? "item_fields" : "task_fields"][eachAttributeKey] = finalValue;
          });

          tracker_serial = payload.tracker_serial;
          if (autoGenerateSensorProfile) {
            tracker_serial = spVids[index];
          }

          workOrders.push({
            sensorProfileVid: tracker_serial.toUpperCase().substring(0, sensorProfileVidCharLimit),
            identifier: currentIdentifier,
            item: { ...payload.item_fields },
            task: { ...payload.task_fields }
          });
        }

        await workOrderClient.createWorkOrderBatch(workOrders, onboardingLocationId);

        if (additionalFeatures.includes("printerEnabled") && !printLater) {
          const { dimensions = { x: 4, y: 6 }, printDensity = 300, properties = [] } = tagConfig;
          for (const workOrder of workOrders) {
            const woIdentifierSplit = workOrder.identifier.split("-");
            const woIndex = woIdentifierSplit[woIdentifierSplit.length - 1];
            const currentWorkOrder = {
              ...workOrder,
              ...workOrder.item,
              ...workOrder.task,
              rowCount: `${parseInt(woIndex)} of ${workOrder.task.total_skid_quantity_ts}`
            };
            if (currentWorkOrder.sensorProfileVid) {
              const itemList = properties.map((property) => {
                return {
                  label: property.label,
                  index: property.index,
                  value: currentWorkOrder[property.id] || ""
                };
              });
              await printTagV1(
                dimensions,
                printDensity,
                "",
                false,
                "",
                currentWorkOrder.sensorProfileVid,
                itemList,
                currentWorkOrder.status_flags[0] ? currentWorkOrder.status_flags[0] : ""
              );
            }
          }
        }
      } else {
        if (autoGenerateSensorProfile) {
          tracker_serial = generateSensorProfileVidUsingInput(identifier)
            .toUpperCase()
            .substring(0, sensorProfileVidCharLimit);
        }
        if (useV2API) {
          const { task_fields = {} } = payload;
          delete task_fields.category;
          delete task_fields.identifier;
          const {
            customer,
            po_number,
            comments,
            due_date,
            status,
            state,
            start_date,
            completion_date,
            description,
            priority,
            ...custom_properties
          } = task_fields;

          const createWorkOrderSetPayload = {
            order_number: identifier,
            tracker_serial,
            customer,
            po_number,
            comments,
            due_date,
            status,
            state,
            start_date,
            completion_date,
            description,
            priority,
            custom_properties
          };

          if (onboardingLocationAttribute?.onboardingLocation.value?.value) {
            createWorkOrderSetPayload.onboarding_location = onboardingLocationAttribute.onboardingLocation.value.value;
          }
          Object.keys(createWorkOrderSetPayload).forEach((key) => {
            return createWorkOrderSetPayload[key] === undefined ? delete createWorkOrderSetPayload[key] : {};
          });

          try {
            const response = await appsyncWorkOrderClient.createWorkOrderSet([createWorkOrderSetPayload]);
            const { data, errors } = response;
            if (errors) {
              const [error] = errors;
              throw error;
            }
          } catch (e) {
            setShowBanner(true);
            let errorMsg;
            if (e.message.includes("duplicate traveller_identifier is not allowed")) {
              errorMsg = "Work order number already exists. Please try another.";
            } else if (e.message.includes("WorkOrder with number") && e.message.includes("already exists")) {
              errorMsg = `Failed to create work order. Work Order with number ${identifier} already exists.`;
            } else {
              errorMsg = "Failed to create work order. Please contact Xemelgo Support for assistance.";
            }
            setBannerMessage(errorMsg);
            sendMixPanelEvent(WORK_ORDER_ONBOARDING_V2, WORK_ORDER_ONBOARDING_V2_STEPS.ITEM_ONBOARD_FAILED, {
              error_message: errorMsg
            });

            setIsWorkOrderSectionLoading(false);
            setIsInputPartTableLoading(false);
            setIsOperationTableLoading(false);
            return;
          }

          try {
            if (additionalFeatures.includes("printerEnabled") && customTemplate) {
              await printCustomTag(customTemplate, {
                ...createWorkOrderSetPayload,
                ...createWorkOrderSetPayload.custom_properties
              });
            } else if (bartenderUrl) {
              const workOrderToPrintObject = {
                ...createWorkOrderSetPayload,
                ...createWorkOrderSetPayload.custom_properties
              };
              await bartenderPrintTag(workOrderToPrintObject);
            }
          } catch (e) {
            setShowBanner(true);
            setIsWorkOrderSectionLoading(false);
            setIsInputPartTableLoading(false);
            setIsOperationTableLoading(false);
            setBannerMessage(e);
            return;
          }
        } else {
          try {
            createdWO = await workOrderClient.createWorkOrderV2(
              payload.identifier,
              payload.task_fields,
              undefined, // bom ID
              undefined, // routing ID
              payload.output_items[0].id,
              payload.output_items[0].quantity,
              payload.output_items[0].unit
            );
            if (createdWO?.createWorkOrder?.id) {
              await workOrderClient.createTravellerForTask(
                createdWO.createWorkOrder.identifier,
                createdWO.createWorkOrder.id
              );
            }
          } catch (e) {
            setShowBanner(true);
            setBannerMessage("Failed to create work order. Please contact Xemelgo Support for assistance.");
            sendMixPanelEvent(WORK_ORDER_ONBOARDING_V2, WORK_ORDER_ONBOARDING_V2_STEPS.ITEM_ONBOARD_FAILED, {
              error_message: e
            });
            setIsWorkOrderSectionLoading(false);
            setIsInputPartTableLoading(false);
            setIsOperationTableLoading(false);
            return;
          }
        }
      }
      if (isBomEnabled) {
        const pdfInfo = await workOrderClient.getWorkOrderInfoForPDF(
          createdWO?.createWorkOrder?.id,
          isBomEnabled,
          ["location_ts"], // task tenant specific properties
          ["gauge_ts"] // item type tenant specific properties
          // ['gauge_ts', 'stretchout_ts', 'sticker_ts', 'tape_ts'] // item type tenant specific properties
        );

        // adding on_hand_quantity attribute to inputParts in pdfInfo
        const { inputParts } = pdfInfo.taskBOMData;
        inputParts.forEach((inputPart) => {
          const matchedInputItem = payload.input_items.find((input_item) => {
            return input_item.itemTypeId === inputPart.id;
          });
          inputPart.on_hand_quantity = matchedInputItem.onHandQuantity;
        });

        const customerLogo = await getLogo();
        setPdfData({
          customerLogo,
          taskData: pdfInfo.taskData,
          taskBOMData: pdfInfo.taskBOMData,
          taskRoutingData: pdfInfo.taskRoutingData,
          outputPartData: pdfInfo.outputPartData
        });
      } else {
        setIsWorkOrderSectionLoading(false);
        setIsInputPartTableLoading(false);
        setIsOperationTableLoading(false);
        clearFormInput();
        setShowBanner(true);
        setIsBannerError(false);
        setBannerMessage("Work order created successfully!");
      }

      sendMixPanelEvent(WORK_ORDER_ONBOARDING_V2, WORK_ORDER_ONBOARDING_V2_STEPS.ITEM_ONBOARD_SUCCESS, {
        item_onboarded_count: Array.isArray(payload.identifier) ? payload.identifier.length : 1
      });
    } else {
      setIsWorkOrderSectionLoading(false);
      setIsInputPartTableLoading(false);
      setIsOperationTableLoading(false);
    }
  };

  useEffect(() => {
    let count = 1;
    if (isPDFLoaded && count === 1) {
      PDFDownloadButtonRef.current.click();
      setIsPDFLoaded(false);
      count--;
      setIsWorkOrderSectionLoading(true);
      setIsInputPartTableLoading(true);
      setIsOperationTableLoading(true);
      onCloseClick(true);
    }
  }, [isPDFLoaded]);

  const pdfDownloadButton = () => {
    if (!pdfData) {
      return null;
    }
    return (
      <div style={{ display: "none" }}>
        <BlobProvider
          document={
            <WorkOrderPDF
              customerLogo={pdfData?.customerLogo}
              taskData={pdfData?.taskData}
              taskBOMData={pdfData?.taskBOMData}
              taskRoutingData={pdfData?.taskRoutingData}
              outputPartData={pdfData?.outputPartData}
            />
          }
        >
          {({ blob, url, loading, error }) => {
            if (url && !loading && !error) {
              setIsPDFLoaded(true);
            }
            if (pdfData) {
              return (
                <a
                  ref={PDFDownloadButtonRef}
                  href={url}
                  download={pdfData?.taskData?.identifier || "workOrder.pdf"}
                >
                  Download
                </a>
              );
            }
          }}
        </BlobProvider>
      </div>
    );
  };

  const renderBomSection = () => {
    return (
      <div className={`${Style.bom_table_list} ${Style.flex_column}`}>
        <BOMCreateOrderInputPartTable
          ref={inputPartTableRef}
          loading={isInputPartTableLoading}
          onChange={(newInputGroupInfo) => {
            setNewInputGroupInfo(newInputGroupInfo);
          }}
        />
        <BOMCreateOrderOperationTable
          ref={operationTableRef}
          loading={isOperationTableLoading}
        />
        {!isWorkOrderSectionLoading && (
          <div className={`${Style.remark_container}`}>
            <p>Remarks</p>
            {isInputPartTableLoading ? (
              [...new Array(3)].map((_, index) => {
                return (
                  <Skeleton
                    key={index}
                    height={28}
                  />
                );
              })
            ) : (
              <AutoSizeTextArea
                newLineAllowed
                minRows={5}
                maxRows={5}
                value={bomSectionData.comments}
                onChangeText={(newText) => {
                  bomSectionData.comments = newText;
                  setBomSectionData({
                    ...bomSectionData
                  });
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const renderSubFeatureSection = () => {
    if (isBomEnabled) {
      return renderBomSection();
    }
    if (groupOnboardingEnabled) {
      return (
        <GroupOnboardingWorkOrderSection
          ref={groupOnboardingSectionRef}
          isLoading={isWorkOrderSectionLoading}
          onChangePrintLater={setPrintLater}
          Style={Style}
          showPrintLaterOption
        />
      );
    }
  };

  const renderAdditionalFeaturesSection = () => {
    return additionalFeatures.map((additionalFeature) => {
      switch (additionalFeature) {
        case "commentsEnabled":
          return (
            <div
              className={Style.additional_feature_container}
              key={additionalFeature}
            >
              <p>Additional Comments</p>
              <AutoSizeTextArea
                newLineAllowed
                minRows={5}
                maxRows={5}
                value={comments}
                onChangeText={setComments}
              />
            </div>
          );
        case "printerEnabled":
          return (
            <div
              key={additionalFeature}
              className={Style.additional_feature_container}
            >
              <PrintSection
                isPrintEnabled
                quantity={1}
                onChangeQuantity={() => {}}
                printers={printers}
                selectedPrinter={selectedPrinterInfo}
                onSelectPrinter={onSelectPrinter}
                contentLoading={printerSectionLoading}
                printerError={null}
                disableQuantity
                printerLabel="Printer Information"
              />
            </div>
          );
        default:
          break;
      }
    });
  };

  const QuantityCheckModal = () => {
    return (
      <Modal
        className={`${Style.quantity_check_modal}`}
        backdrop="static"
        backdropClassName={`${Style.quantity_check_modal_backdrop}`}
        show={showQuantityCheckModal}
      >
        <Modal.Header className={`${Style.quantity_check_modal_header}`}>
          <Modal.Title bsPrefix={`${Style.quantity_check_modal_title}`}>Quantity Check</Modal.Title>
          <WarningOutlined className={`${Style.quantity_check_modal_icon}`} />
        </Modal.Header>
        <Modal.Body className={`${Style.body_container}`}>
          <>
            <p>
              You don't have sufficient quantity for: <b>{inSufficientQuantityParts.toString()}</b> to complete your
              work order. Please leave a note to override and continue.
            </p>
            <AutoSizeTextArea
              newLineAllowed
              minRows={5}
              maxRows={5}
              value={quantityCheckNote}
              onChangeText={(newText) => {
                setQuantityCheckNote(newText);
              }}
            />
          </>
        </Modal.Body>
        <Modal.Footer className={`${Style.footer_container}`}>
          <button
            onClick={() => {
              setShowQuantityCheckModal(false);
            }}
          >
            Cancel
          </button>
          <button
            disabled={!quantityCheckNote.length}
            onClick={() => {
              bomSectionData.comments += `\n${quantityCheckNote}`;
              setBomSectionData({
                ...bomSectionData
              });
              setShowQuantityCheckModal(false);
              onCreateClick();
            }}
          >
            Continue
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <ModalForm
      show
      prefix="create_work_order-modal"
      className={`${Style.modal} ${Style.flex_column}`}
      title={
        <CreateWorkOrderModalHeader
          onCloseClick={() => {
            if (onCloseClick) {
              onCloseClick();
            } else {
              history.goBack();
            }
          }}
        />
      }
      body={
        <div className={`${Style.body_container} ${Style.flex_column}`}>
          {showBanner && (
            <DisplayBanner
              bannerError={isBannerError}
              bannerMessage={bannerMessage}
              onCloseBanner={() => {
                setIsBannerError(true); // default to error banner unless specified otherwise
                return setShowBanner(false);
              }}
            />
          )}
          {renderWorkOrderSection()}
          {pdfDownloadButton()}
          {QuantityCheckModal()}
          {renderSubFeatureSection()}
          {!isWorkOrderSectionLoading && renderAdditionalFeaturesSection()}
        </div>
      }
      footer={
        <CreateWorkOrderModalFooter
          onDiscardClick={onDiscardClick}
          onSaveClick={
            inSufficientQuantityParts.length
              ? () => {
                  setShowQuantityCheckModal(true);
                }
              : onCreateClick
          }
          isCreateButtonDisabled={isWorkOrderSectionLoading || isOperationTableLoading || isInputPartTableLoading}
        />
      }
    />
  );
};

export default (props) => {
  return (
    <BOMManagementContextProvider>
      <CreateWorkOrderModal {...props} />
    </BOMManagementContextProvider>
  );
};