import React from "react";
import { VALUE_TYPE_MAP } from "../../data/constants";
import { getFormattedDate } from "../../../../common/utils";
import Style from "../../PaginatedListTableWithTypes.module.css";
import { msToTime } from "../../../../common/Utilities";

const getStatusRenderComponent = (statusList) => {
  return (
    <div className={`${Style.flex_row} ${Style.status_container}`}>
      {statusList?.length
        ? statusList.map((eachStatus) => {
            return (
              <div
                key={eachStatus.title}
                className={Style.status}
                style={{ backgroundColor: eachStatus.color }}
              >
                {eachStatus.title}
              </div>
            );
          })
        : "-"}
    </div>
  );
};

export const getValueByType = (value, type, timeFormat, csvMode) => {
  switch (type) {
    case VALUE_TYPE_MAP.boolean:
      return typeof value !== "undefined" ? (value ? "Yes" : "No") : "-";
    case VALUE_TYPE_MAP.dateTime:
      return value ? getFormattedDate(value, timeFormat || "hh:mm A MMM Do") : "-";
    case VALUE_TYPE_MAP.date:
      return value ? getFormattedDate(value, timeFormat || "MMM Do") : "-";
    case VALUE_TYPE_MAP.duration:
      return value !== undefined ? msToTime(value) : "-";
    case VALUE_TYPE_MAP.status:
      return csvMode
        ? value?.length
          ? value.reduce((accumulator, eachStatus) => {
              return `${accumulator && `${accumulator}, `}${eachStatus.title}`;
            }, "")
          : "-"
        : getStatusRenderComponent(value);
    default:
      return value || "-";
  }
};
