import React, { useEffect, useState, useRef } from "react";
import { GroupOnboardingWorkOrderSection } from "features/order-track-page-feature/features/group-onboarding-work-order-section/GroupOnboardingWorkOrderSection";
import { getCurrentTimestamp } from "common/Utilities";
import { useXemelgoClient } from "services/xemelgo-service";
import { ModalFormFooter } from "../../../print-tags-modal/components/modal-form-footer/ModalFormFooter";
import { ModalForm } from "../../../../components/modal-form";
import Style from "./CreateAndPrintTagsModal.module.css";
import DisplayBanner from "../../../../components/display-banner/DisplayBanner";
import useGeneratePrintTagPayload from "../../../../hooks/use-generate-print-tag-payload";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import { generateSensorProfileVidUsingInput } from "../../utils/AddOrderUtils";
import getCreateValue from "../../utils/form-utils/get-create-value";
import useAutoGenerateWorkOrderIdentifiers from "../../hooks/use-auto-generate-work-order-identifiers/useAutoGenerateWorkOrderIdentifiers";
import usePrintService from "../../../../hooks/use-print-service";
import { PRINT_TYPES, SOLUTIONS } from "../../../../data/constants";
import PrintSection from "../../../../components/print-section";

const APP_ID = "order";
const FEATURE_ID = "addOrder";
const GROUP_ONBOARDING_KEY = "rowCount";
const PRINT_LATER_KEY = "print_later";
export const CreateAndPrintTagsModal = ({ order, orderId, onCloseModal }) => {
  const { generatePrintCommandBySolution } = useGeneratePrintTagPayload(SOLUTIONS.WORK_ORDER, PRINT_TYPES.ZPL);
  const {
    printers,
    printerErrorMessage,
    selectedPrinterInfo,
    isLoading: isPrinterLoading,
    onSelectPrinter,
    print
  } = usePrintService(true, PRINT_TYPES.ZPL);
  const { generate: generateWorkOrderIdentifiers } = useAutoGenerateWorkOrderIdentifiers();
  const configProvider = useAppConfigProvider(APP_ID);

  const [bannerError, setBannerError] = useState(false);
  const [bannerErrorMessage, setBannerErrorMessage] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [attributeGroups, setAttributeGroups] = useState([]);
  const [sensorProfileVidCharLimit, setSensorProfileVidCharLimit] = useState(null);
  const [customTemplate, setCustomTemplate] = useState("");
  const [tagConfig, setTagConfig] = useState({});
  const [autoPublishAfterPrintLocationId, setAutoPublishAfterPrintLocationId] = useState("");
  const groupOnboardingSectionRef = useRef(null);

  const xemelgoClient = useXemelgoClient();
  const [workOrderClient] = useState(xemelgoClient.getWorkOrderClient());
  const [publishClient] = useState(xemelgoClient.getPublishClient());
  const [sensorProfileClient] = useState(xemelgoClient.getSensorProfileClient());

  const onLoad = () => {
    const {
      customTemplate: newCustomTemplate = "",
      autoPublishAfterPrintLocationId: newAutoPublishAfterPrintLocationId = "",
      attributeGroups: newAttributeGroups = [],
      sensorProfileVidCharLimit: newSensorProfileVidCharLimit = 2048,
      tagConfig: newTagConfig = {}
    } = configProvider.getValue(FEATURE_ID, "object") || {};

    setCustomTemplate(newCustomTemplate);
    setAutoPublishAfterPrintLocationId(newAutoPublishAfterPrintLocationId);
    setAttributeGroups(newAttributeGroups);
    setSensorProfileVidCharLimit(newSensorProfileVidCharLimit);
    setTagConfig(newTagConfig);
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (printerErrorMessage) {
      setBannerError(true);
      setBannerErrorMessage(printerErrorMessage);
    }
  }, [printerErrorMessage]);

  const onSubmit = async () => {
    // validate form fields
    setIsSubmitLoading(true);
    if (!groupOnboardingSectionRef.current?.validateGroupOnboardingFields()) {
      setIsSubmitLoading(false);
      return;
    }

    const { groupOnboardingAttributes, groupOnboardingRows } = groupOnboardingSectionRef.current.getFormData();
    if (groupOnboardingRows.length === 0) {
      setIsSubmitLoading(false);
      return;
    }

    let tracker_serial = null;
    const payload = {
      task_fields: {
        category: "Tracked Work Order"
      },
      item_fields: {}
    };

    // autogenerate identifiers
    const autoGenerateErrorMessage = "Auto-generate work order number failed. Please try again.";
    let identifiers;
    try {
      identifiers = await generateWorkOrderIdentifiers(order.identifier, groupOnboardingRows.length, "-");
    } catch (error) {
      console.error(error);
      setBannerError(true);
      setBannerErrorMessage(autoGenerateErrorMessage);
      setIsSubmitLoading(false);
      return;
    }

    // initialize payload w/ non tag-specific order details
    const orderData = {};
    Object.entries(order).forEach(([key, value]) => {
      if (key.startsWith("task-")) {
        const newKey = key.replace("task-", "");
        orderData[newKey] = value;
      } else {
        orderData[key] = value;
      }
    });

    attributeGroups.forEach((eachGroupAttributes) => {
      Object.keys(eachGroupAttributes)
        .filter((eachAttributeKey) => {
          return !eachGroupAttributes[eachAttributeKey].skipForCreation;
        })
        .forEach((eachAttributeKey) => {
          const { propertyFor } = eachGroupAttributes[eachAttributeKey];
          const finalValue = orderData[eachAttributeKey];
          if (finalValue === null) {
            return;
          }
          if (eachAttributeKey === "output_items") {
            payload[eachAttributeKey] = [
              {
                itemType_identifier: finalValue
              }
            ];
          } else {
            payload[propertyFor === "item" ? "item_fields" : "task_fields"][eachAttributeKey] = finalValue;
          }
        });
    });

    payload.identifier = identifiers;

    // add tag-specific details to payload, form list of work orders
    Object.keys(groupOnboardingAttributes).forEach((eachAttributeKey) => {
      if (eachAttributeKey === PRINT_LATER_KEY) {
        return;
      }

      const { propertyFor } = groupOnboardingAttributes[eachAttributeKey];
      const finalValue = getCreateValue(groupOnboardingAttributes[eachAttributeKey]);
      if (eachAttributeKey === GROUP_ONBOARDING_KEY) {
        return;
      }

      payload[propertyFor === "item" ? "item_fields" : "task_fields"][eachAttributeKey] = finalValue;
    });

    const spVids = generateSensorProfileVidUsingInput(payload.identifier, getCurrentTimestamp());

    const workOrders = payload.identifier.map((currentIdentifier, index) => {
      const currentRow = groupOnboardingRows[index];

      Object.keys(currentRow).forEach((eachAttributeKey) => {
        const { propertyFor } = currentRow[eachAttributeKey];
        const finalValue = getCreateValue(currentRow[eachAttributeKey]);

        payload[propertyFor === "item" ? "item_fields" : "task_fields"][eachAttributeKey] = finalValue;
      });

      tracker_serial = payload.tracker_serial;
      tracker_serial = spVids[index];

      return {
        sensorProfileVid: tracker_serial.toUpperCase().substring(0, sensorProfileVidCharLimit),
        identifier: currentIdentifier,
        item: { ...payload.item_fields },
        task: { ...payload.task_fields }
      };
    });

    try {
      await workOrderClient.createWorkOrderBatch(workOrders);
    } catch (error) {
      console.error(error);
      setBannerError(true);
      setBannerErrorMessage("Failed to create work orders.");
      setIsSubmitLoading(false);
      return;
    }

    try {
      await workOrderClient.deleteWorkOrders([orderId]);
    } catch (error) {
      console.error(error);
    }

    const workOrdersToPrint = workOrders.map((workOrder) => {
      return {
        ...workOrder,
        ...workOrder.item,
        ...workOrder.task,
        order_number: workOrder.identifier,
        tracker_serial: workOrder.sensorProfileVid
      };
    });

    for (const workOrderToPrint of workOrdersToPrint) {
      const printZPLCommand = await generatePrintCommandBySolution(workOrderToPrint, { customTemplate, tagConfig });

      try {
        if (autoPublishAfterPrintLocationId) {
          const sensorProfile = await sensorProfileClient.getSensorProfileByVid(workOrderToPrint.sensorProfileVid);
          if (sensorProfile?.id) {
            await publishClient.publishUserEventWithSensorProfile([sensorProfile.id], autoPublishAfterPrintLocationId);
          }
        }
        await print(printZPLCommand);
      } catch (e) {
        setIsSubmitLoading(false);
        onCloseModal(true);
        return;
      }
    }

    setIsSubmitLoading(false);
    onCloseModal(true);
  };

  return (
    <ModalForm
      show
      title={<div className={Style.modal_title}>Print Tags</div>}
      body={
        <div className={Style.modal_body}>
          {bannerError && (
            <DisplayBanner
              bannerError
              bannerMessage={bannerErrorMessage}
              onCloseBanner={() => {
                setBannerError(false);
              }}
            />
          )}
          <div>
            You are about to print tags for the selected order. Please select your printer and input the print details
            to proceed.
          </div>
          <br />
          <PrintSection
            isPrintEnabled
            singlePrint
            printers={printers}
            selectedPrinter={selectedPrinterInfo}
            onSelectPrinter={onSelectPrinter}
            contentLoading={isPrinterLoading}
            printerError={null}
            disableQuantity
            printerLabel="Select your printer:"
          />
          <GroupOnboardingWorkOrderSection
            ref={groupOnboardingSectionRef}
            showPrintLaterOption={false}
            Style={Style}
          />
        </div>
      }
      footer={
        <ModalFormFooter
          onConfirm={onSubmit}
          onCancel={() => {
            onCloseModal(false);
          }}
          confirmDisabled={isSubmitLoading || Object.keys(selectedPrinterInfo).length === 0}
          cancelDisabled={isSubmitLoading}
        />
      }
      centered
    />
  );
};