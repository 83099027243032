import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import Style from "./PaginatedListTableWithTypes.module.css";
import useSortableHeader from "../../hooks/use-sortable-header";
import PaginatedList from "../PaginatedList/PaginatedList";
import OptionalLink from "../optional-link/OptionalLink";
import { SORT_DIRECTION } from "../../data/constants";
import { getValueByType } from "./utils/get-value-by-type";

export const PaginatedListTableWithTypes = ({
  headers,
  dataList,
  getTitleURLCallbackFn,
  isLoading,
  sortIdReplacementMap,
  columnSortDisabled,
  renderHeader,
  renderItem,
  headerRowContainerClassName,
  headerContainerClassName,
  itemContainerClassName,
  itemRowContainerClassName,
  emptyListContainerClassName,
  selectable,
  ...restProps
}) => {
  const { defaultOrderBy, defaultOrderDirection } = useMemo(() => {
    if (columnSortDisabled) {
      return {};
    }

    const defaultHeader =
      headers.find((eachHeader) => {
        return eachHeader.defaultSort;
      }) ||
      headers[0] ||
      {};

    return {
      defaultOrderBy: defaultHeader.id,
      defaultOrderDirection: defaultHeader.defaultDirection || SORT_DIRECTION.ascending
    };
  }, [headers, columnSortDisabled]);

  const { order, orderBy, getSortedData, getSortableHeader } = useSortableHeader(defaultOrderDirection, defaultOrderBy);

  const sortedDataList = useMemo(() => {
    return columnSortDisabled ? dataList : getSortedData(dataList, sortIdReplacementMap[orderBy]);
  }, [dataList, order, orderBy, sortIdReplacementMap, columnSortDisabled]);

  const LoadingComponent = () => {
    const numColumns = headers?.length || 3;
    return (
      <div
        className={`${Style.flex_row} ${
          selectable ? Style.table_item_selectable : Style.table_item
        } ${itemRowContainerClassName}`}
      >
        {[...Array(numColumns)].map((_, index) => {
          return (
            <div
              key={index}
              className={`${Style.flex_row} ${Style.table_column} ${itemContainerClassName}`}
            >
              <Skeleton
                containerClassName={Style.loading_icon}
                height={24}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <PaginatedList
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      selectable={selectable}
      data={sortedDataList}
      header={headers}
      headerContainerClassName={`${Style.table_header} ${headerRowContainerClassName}`}
      renderEmptyList={() => {
        if (isLoading) {
          return [...Array(restProps.numItemsPerPage || 10)].map((_, index) => {
            return <LoadingComponent key={index} />;
          });
        }
        return (
          <div className={`${Style.flex_row} ${Style.empty_list_container} ${emptyListContainerClassName}`}>
            <p className={Style.empty_list_text}>No Data Available</p>
          </div>
        );
      }}
      renderHeader={
        renderHeader ||
        ((eachHeader) => {
          return getSortableHeader(eachHeader, {
            headerContainerClass: `${Style.flex_row} ${Style.table_column} ${headerContainerClassName}`,
            className: Style.table_header_text,
            disabled: columnSortDisabled
          });
        })
      }
      renderItem={(eachItem) => {
        if (isLoading) {
          return <LoadingComponent />;
        }

        if (renderItem) {
          return renderItem(eachItem);
        }

        return (
          <div
            key={eachItem.identifier}
            className={`${Style.flex_row} ${
              selectable ? Style.table_item_selectable : Style.table_item
            } ${itemRowContainerClassName}`}
          >
            {headers.map((eachHeader, subIndex) => {
              return (
                <OptionalLink
                  key={`${eachItem[eachHeader.id]}${subIndex}`}
                  className={`${Style.flex_row} ${Style.table_column} ${itemContainerClassName}`}
                  active={!!eachHeader.isNavLink}
                  route={getTitleURLCallbackFn(eachItem)}
                >
                  <div
                    className={`${Style.table_item_text} ${eachHeader.isNavLink && Style.table_item_text_clickable}`}
                  >
                    {getValueByType(eachItem[eachHeader.id], eachHeader.type, eachHeader.timeFormat)}
                  </div>
                </OptionalLink>
              );
            })}
          </div>
        );
      }}
    />
  );
};

PaginatedListTableWithTypes.defaultProps = {
  dataList: [],
  headers: [],
  getTitleURLCallbackFn: () => {
    return "";
  },
  isLoading: false,
  sortIdReplacementMap: {},
  columnSortDisabled: false,
  renderHeader: null,
  renderItem: null,
  headerRowContainerClassName: "",
  headerContainerClassName: "",
  itemContainerClassName: "",
  itemRowContainerClassName: "",
  emptyListContainerClassName: "",
  selectable: false
};

PaginatedListTableWithTypes.propTypes = {
  dataList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
  headers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
  getTitleURLCallbackFn: PropTypes.func,
  isLoading: PropTypes.bool,
  sortIdReplacementMap: PropTypes.object,
  columnSortDisabled: PropTypes.bool,
  renderHeader: PropTypes.func,
  renderItem: PropTypes.func,
  headerRowContainerClassName: PropTypes.string,
  headerContainerClassName: PropTypes.string,
  itemContainerClassName: PropTypes.string,
  itemRowContainerClassName: PropTypes.string,
  emptyListContainerClassName: PropTypes.string,
  selectable: PropTypes.bool
};
