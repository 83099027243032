import React, { useContext, useEffect, useState } from "react";
import { useAddInventoryFeatureV2ConfigContext } from "../add-inventory-feature-v2-config-context";
import { TAB_OPTIONS } from "../../../../../../data/constants";
import useOnboardingForm from "../../../../../../hooks/use-onboarding-form";
import { STATUS_OPTIONS } from "../../../../../../components/status-popup-component/data/constants";

const AddInventoryFeatureV2StateContext = React.createContext({
  formData: {},
  setFormData: () => {},
  isSubmitDisabled: false,
  setIsSubmitDisabled: () => {},
  submitStatus: STATUS_OPTIONS.NONE,
  setSubmitStatus: () => {}
});

export const useAddInventoryFeatureV2StateContext = () => {
  return useContext(AddInventoryFeatureV2StateContext);
};

export const AddInventoryFeatureV2StateContextProvider = ({ children }) => {
  const { defaultOnboardingTab, formSections } = useAddInventoryFeatureV2ConfigContext();
  const { fetchFormOptions } = useOnboardingForm();

  const [formDataMap, setFormDataMap] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(STATUS_OPTIONS.NONE);
  const [formFields, setFormFields] = useState({});
  const [fetchedFormSections, setFetchedFormSections] = useState([]);
  const [selectedOnboardingMode, setSelectedOnboardingMode] = useState(defaultOnboardingTab);
  const [currentTab, setCurrentTab] = useState(TAB_OPTIONS.SINGLE);

  const processFormInfo = async (newFormSections) => {
    const { fetchedFormSections: newFetchedFormSections, fetchedFormFields } = await fetchFormOptions(
      newFormSections,
      "Inventory"
    );

    const updatedFormSections = newFetchedFormSections.map((section) => {
      const updatedSection = section.map((field) => {
        const updatedField = { ...field };

        // hide tracker serial and print quantity input on the form when user selects "Add" onboarding mode
        // as these fields will be populate by the Station Section
        if (field.id === "tracker_serial") {
          updatedField.isHidden = selectedOnboardingMode === "add" && currentTab === TAB_OPTIONS.SINGLE;
        }
        if (field.id === "print_quantity") {
          updatedField.isHidden = selectedOnboardingMode === "add" && currentTab === TAB_OPTIONS.SINGLE;
        }

        return updatedField;
      });
      return updatedSection;
    });

    setFetchedFormSections(updatedFormSections);
    setFormFields(fetchedFormFields);
  };

  useEffect(() => {
    processFormInfo(formSections);
  }, [selectedOnboardingMode, formSections, currentTab]);

  return (
    <AddInventoryFeatureV2StateContext.Provider
      value={{
        formDataMap,
        setFormDataMap,
        isSubmitDisabled,
        setIsSubmitDisabled,
        submitStatus,
        setSubmitStatus,
        formFields,
        fetchedFormSections,
        selectedOnboardingMode,
        setSelectedOnboardingMode,
        currentTab,
        setCurrentTab
      }}
    >
      {children}
    </AddInventoryFeatureV2StateContext.Provider>
  );
};
