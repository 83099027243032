import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Home as HomeIcon } from "@material-ui/icons";

export const useBreadcrumbBuilder = ({ selectedLocationId, locationTreeMap }) => {
  const { pathname, search } = useLocation();

  const breadcrumbDataList = useMemo(() => {
    const currentParsedSearchString = queryString.parse(search);

    const dataList = [];
    let tempLocationId = selectedLocationId;
    while (tempLocationId) {
      const { name, id, directParentId } = locationTreeMap[tempLocationId] || {};

      const searchString = queryString.stringify({ ...currentParsedSearchString, locationId: id });
      dataList.unshift({ title: name, path: `${pathname}${searchString ? `?${searchString}` : ""}` });
      tempLocationId = directParentId;
    }
    dataList.unshift({
      title: <HomeIcon data-cy="home-icon" />,
      path: `${pathname}?${queryString.stringify({ ...currentParsedSearchString, locationId: null })}`
    });
    return dataList;
  }, [selectedLocationId, locationTreeMap, search]);

  return { breadcrumbDataList };
};
